export const DASHBOARD_SECTION_CLIENTS = "clients"
export const DASHBOARD_SECTION_BOOKED_SESSIONS = "sessions"
export const DASHBOARD_SECTION_QUIZ_LEADS = "quiz"
export const DASHBOARD_SECTION_BALANCE_TOOL = "balance"
export const DASHBOARD_SECTION_EDIT_PROFILE = "profile"
export const DASHBOARD_SECTION_COACH_TRAINING = "training"
export const DASHBOARD_SECTION_CONTENT_COPILOT = "content_copilot"
export const DASHBOARD_SECTION_CLEAR_BUSINESS = "clear_business"
export const DASHBOARD_SECTION_COACH_COPILOT = "coach_copilot"
export const DASHBOARD_SECTION_AFFILIATE = "affiliate"
export const DASHBOARD_SECTION_GIFT_APP_ACCESS = 'gift_app_access'
export const DASHBOARD_SECTION_SETTINGS = "settings"
export const DASHBOARD_SECTION_LOGOUT = "logout"
export const DASHBOARD_SECTION_FAQ = "faq"
export const DASHBOARD_SECTION_FEATURE_REQUESTS = "feature_requests"
export const DASHBOARD_SECTION_SUPPORT = "support"
export const DASHBOARD_SECTION_LEGAL_AND_DATA = "legal"
export const DASHBOARD_SECTION_ADMIN_SEO = "seo"

const VALID_DASHBOARD_SECTIONS = new Set([
	DASHBOARD_SECTION_CLIENTS,
	DASHBOARD_SECTION_BOOKED_SESSIONS,
	DASHBOARD_SECTION_QUIZ_LEADS,
	DASHBOARD_SECTION_EDIT_PROFILE,
	DASHBOARD_SECTION_COACH_TRAINING,
	DASHBOARD_SECTION_CONTENT_COPILOT,
	DASHBOARD_SECTION_CLEAR_BUSINESS,
	DASHBOARD_SECTION_COACH_COPILOT,
	DASHBOARD_SECTION_AFFILIATE,
	DASHBOARD_SECTION_GIFT_APP_ACCESS,
	DASHBOARD_SECTION_FAQ,
	DASHBOARD_SECTION_ADMIN_SEO,
	// DASHBOARD_SECTION_SETTINGS,
	// DASHBOARD_SECTION_SUPPORT,
	// DASHBOARD_SECTION_LEGAL_AND_DATA,
	DASHBOARD_SECTION_LOGOUT
])
export const currentTabIsValid = tab => VALID_DASHBOARD_SECTIONS.has(tab)