import React, { Suspense } from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  sanitizeEmailAddress,
  sanitizeUsername,
  sanitizeUsdCentsString,
  sanitizeNumber,
  ProductionCertificationUrl,
  ProductionCoachOSUrl,
  getCoachProfileLink,
  getCoachProfileImageUrl,
  httpPrefix,
  trimHttpPrefix,
  DevicePixelRatio,
  delay,
} from '../../../utils'

import {
  ActivityArrowSvg,
  ExternalLinkSvg,
  OvalSpinnerSvg,
  InfoSvg,
} from '../../../constants/svgs'

import {
  EditSvg,
  RefreshSvg,
} from '../../../constants/dashboardSvgs'

import TrainerAcceptClientBookingExplanationModal from '../../modals/TrainerAcceptClientBookingExplanationModal'

import TextFeedbackPrompt from '../TextFeedbackPrompt'
import SendRequestButton from '../SendRequestButton'
import ConfirmSection from '../ConfirmSection'
import CompletionCheckIcon from '../CompletionCheckIcon'

const CountryDropdown = React.lazy(() => import('../CountryDropdown'));

const MaxTitleLength = 50
const MinCoachingNicheDescriptionLength = 100
const MaxCoachingNicheDescriptionLength = 300
const MinHourlyRateUsdCents = 10000
const ProfileImageSizePixels = 200
const OverlaySuccessDurationMs = 1500

class ProfileLinkTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        profileDetails: {
          username: "",
          first_name: "",
          last_name: "",
          email_address: "",
          occupation: "",
          background_experience_title: "",
          background_experience_description: "",
          coaching_niche_title: "",
          coaching_niche_description: "",
          coach_location_country_code: "",
          personal_url: "",
          hourly_rate_usd_cents: 10000,
          is_client_session_booking_enabled: true,
          is_discounted_session_bundling_enabled: true,
          years_professional_experience: null
        },
        profileImageDetails: {
          profile_image_version: 0,
          profile_image_url: "",
          profile_image_file: null
        },
        linkIsCopied: false,
        showProfileImageBackgroundOverlayOnSuccess: false
     }
  }

  async componentDidUpdate(prevProps) {

    const {
      saveUpdatedProfileImageRequestResult,
    } = this.props

    if ((prevProps.saveUpdatedProfileImageRequestResult.state === REQUEST_FETCHING) && (saveUpdatedProfileImageRequestResult.state === REQUEST_SUCCESS)) {
      this.setState({showProfileImageBackgroundOverlayOnSuccess: true})
      await delay(OverlaySuccessDurationMs)
      this.setState({showProfileImageBackgroundOverlayOnSuccess: false})
    }
  }

  componentWillMount() {
    const {
      trainerUuid,
      existingUsername,
      existingFirstName,
      existingLastName,
      existingEmailAddress,
      hasCompletedProfileOnboarding,
      existingOccupation,
      existingBackgroundExperienceTitle,
      existingBackgroundExperienceDescription,
      existingCoachingNicheTitle,
      existingCoachingNicheDescription,
      existingCoachLocationCountryCode,
      existingPersonalUrl,
      existingHourlyRateUsdCents,
      existingIsClientSessionBookingEnabled,
      existingIsDiscountedSessionBundlingEnabled,
      existingProfileImageVersion,
      existingYearsProfessionalExperience
    } = this.props

    const initialUsername = hasCompletedProfileOnboarding ? existingUsername : ""
    const initialHourlyRateUsdCents = hasCompletedProfileOnboarding ? existingHourlyRateUsdCents : 10000
    const initialYearsProfessionalExperience = hasCompletedProfileOnboarding ? existingYearsProfessionalExperience : null
    this.setState({
      profileDetails: {
        ...this.state.profileDetails,
          username: initialUsername,
          first_name: existingFirstName,
          last_name: existingLastName,
          email_address: existingEmailAddress,
          occupation: existingOccupation,
          background_experience_title: existingBackgroundExperienceTitle,
          background_experience_description: existingBackgroundExperienceDescription,
          coaching_niche_title: existingCoachingNicheTitle,
          coaching_niche_description: existingCoachingNicheDescription,
          coach_location_country_code: existingCoachLocationCountryCode,
          personal_url: existingPersonalUrl,
          hourly_rate_usd_cents: initialHourlyRateUsdCents,
          is_client_session_booking_enabled: existingIsClientSessionBookingEnabled,
          is_discounted_session_bundling_enabled: existingIsDiscountedSessionBundlingEnabled,
          years_professional_experience: initialYearsProfessionalExperience
      },
      profileImageDetails: {
        profile_image_version: existingProfileImageVersion || 0,
        profile_image_url: (existingProfileImageVersion > 0) ? getCoachProfileImageUrl(trainerUuid, existingProfileImageVersion) : null,
        profile_image_file: null,
      }
    })
  }

  componentWillUnmount() {
    this.props.onResetSaveProfileLinkUpdates()
    this.props.onResetSaveUpdatedProfileImage()
  }

  render() {

    const {
      trainerUuid,
      timeOfDay,
      onSaveProfileLinkUpdates,
      onSaveUpdatedProfileImage,
      saveProfileLinkUpdatesRequestResult,
      onResetSaveProfileLinkUpdates,
      saveUpdatedProfileImageRequestResult,
      onResetSaveUpdatedProfileImage,
      hasTabAccess,
      existingUsername,
      hasCompletedProfileOnboarding,
      hasCompletedStripeOnboarding,
      existingProfileImageUrl,
      onOpenAffiliateTab,
      onFlashTopErrorMessage,
      isDesktop,
      innerHeight,
      safeAreaInsets,
      onShowAcceptBookingsExplanationModal,
      onShowDiscountedSessionBundlingExplanationModal
    } = this.props

    const {
      profileDetails,
      profileImageDetails,
      linkIsCopied,
      showProfileImageBackgroundOverlayOnSuccess
    } = this.state

    const {
      username,
      first_name,
      last_name,
      email_address,
      coach_location_country_code,
      occupation,
      background_experience_title,
      background_experience_description,
      coaching_niche_title,
      coaching_niche_description,
      personal_url,
      hourly_rate_usd_cents,
      is_client_session_booking_enabled,
      is_discounted_session_bundling_enabled,
      years_professional_experience
    } = profileDetails

    const {
      profile_image_file,
      profile_image_url,
      profile_image_version
    } = profileImageDetails

    const minBackgroundExperienceLengthMet = background_experience_description.length >= MinCoachingNicheDescriptionLength
    const maxBackgroundExperienceLengthExceeded = background_experience_description.length > MaxCoachingNicheDescriptionLength
    const maxBackgroundExperienceTitleLengthExceeded = background_experience_title.length > MaxTitleLength

    const minCoachingNicheLengthMet = coaching_niche_description.length >= MinCoachingNicheDescriptionLength
    const maxCoachingNicheLengthExceeded = coaching_niche_description.length > MaxCoachingNicheDescriptionLength
    const maxCoachingNicheTitleLengthExceeded = coaching_niche_title.length > MaxTitleLength

    const formFieldsDisabled = (saveProfileLinkUpdatesRequestResult.state === REQUEST_FETCHING) || (saveProfileLinkUpdatesRequestResult.state === REQUEST_SUCCESS)
    const formIsIncomplete = (!username || !first_name || !last_name || !email_address || !occupation || !coaching_niche_title || !background_experience_title || !personal_url || (!minBackgroundExperienceLengthMet || maxBackgroundExperienceLengthExceeded) || (!minCoachingNicheLengthMet || maxCoachingNicheLengthExceeded) || maxCoachingNicheTitleLengthExceeded || maxBackgroundExperienceTitleLengthExceeded || (!hourly_rate_usd_cents || hourly_rate_usd_cents < MinHourlyRateUsdCents))
    const saveProfileUpdatesButtonDisabled = formFieldsDisabled || formIsIncomplete

    const saveProfileImageState = saveUpdatedProfileImageRequestResult.state

    const coachProfileLink = getCoachProfileLink(username)

    const disableCopyButton = linkIsCopied || (existingUsername !== username) || !hasCompletedProfileOnboarding

    const showProfileImageDarkBackgroundOverlay = (saveProfileImageState === REQUEST_FETCHING) || ((saveProfileImageState === REQUEST_SUCCESS) && showProfileImageBackgroundOverlayOnSuccess)

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 0, display: "flex", width: "100%", justifyContent: "center"}}
      >
        {hasTabAccess && !hasCompletedStripeOnboarding && (
          <div style={{width: "85%", maxWidth: 500}}>
            <div style={{minWidth: "100%"}}>
              <div style={{backgroundColor: "#FFFFFF", padding: "min(5vw, 22px)", marginTop: "4vh", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginBottom: "2vh"}}>
                  {`COACH PROFILE LINK`}
                </div>
                <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book"}}>
                  {`Seamlessly attract new client leads and earn affiliate commission with your Certified Coach profile link.`}
                </div>
                <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh"}}>
                  {`To create your coach profile, first set up payouts through the Affiliate Tab, then refresh the page:`}
                </div>
                <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                  <button
                    style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                    onClick={() => {
                      onOpenAffiliateTab()
                    }}
                  >
                    <span>
                      {"OPEN AFFILIATE TAB"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {hasTabAccess && hasCompletedStripeOnboarding && (
          <div style={{width: "85%", maxWidth: 500}}>
            <div style={{minWidth: "100%"}}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"hide-fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={`${username === existingUsername}-${hasCompletedProfileOnboarding}`}
                >
                  <div style={{backgroundColor: "#FFFFFF", padding: "min(5vw, 22px)", marginTop: "4vh", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                    <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginBottom: "2vh"}}>
                      {`${hasCompletedProfileOnboarding ? "SHARE" : "SET UP"} PROFILE LINK`}
                    </div>
                    <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book"}}>
                      {`Attract new client leads on demand and earn affiliate commission with your Certified Coach profile link${hasCompletedProfileOnboarding ? ":" : ". To get started, set up your link below."}`}
                    </div>
                    {username === existingUsername && (hasCompletedProfileOnboarding) && (
                      <div style={{marginTop: "3vh"}}>
                        <div>
                          <div style={{textAlign: "center"}}>
                            <div
                              disabled={disableCopyButton}
                              style={{overflow: "hidden", display: "inline-block", width: "80%", userSelect: hasCompletedProfileOnboarding ? "auto" : "none", cursor: disableCopyButton ? "not-allowed" : "pointer", border: "none", WebkitUserSelect: hasCompletedProfileOnboarding ? "auto" : "none", textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: "18px", backgroundColor: "#000000", color: disableCopyButton ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", fontSize: "18px", lineHeight: "18px", fontFamily: "Futura", padding: "12px 18px"}}
                              onClick={() => {
                                if (hasCompletedProfileOnboarding) {
                                  this.setState({linkIsCopied: true})
                                  navigator.clipboard.writeText(coachProfileLink)
                                  setTimeout(() => {
                                    this.setState({linkIsCopied: false})
                                  }, 1000)
                                }
                              }}
                            >
                              {linkIsCopied ? "COPIED LINK!" : (hasCompletedProfileOnboarding ? trimHttpPrefix(coachProfileLink) : "SETUP NEEDED")}
                            </div>
                            {hasCompletedProfileOnboarding && (
                              <div
                                style={{fontFamily: "Futura Medium", marginTop: "1vh", fontSize: "15px", cursor: disableCopyButton ? "not-allowed" : "pointer", lineHeight: "18px", color: "#000000", border: "none"}}
                                disabled={disableCopyButton}
                                onClick={() => {
                                  this.setState({linkIsCopied: true})
                                  navigator.clipboard.writeText(coachProfileLink)
                                  setTimeout(() => {
                                    this.setState({linkIsCopied: false})
                                  }, 1000)
                                }}
                              >
                                {isDesktop ? "CLICK TO COPY" : "TAP TO COPY"}
                              </div>
                            )}
                          </div>
                        </div>
                        <button onClick={() => window.open(coachProfileLink, "_blank", "noreferrer noopener")} style={{border: "none", backgroundColor: "transparent", width: "100%", marginTop: "3vh", padding: "min(2vw, 8px)"}}>
                          <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                            <div style={{display: "inline-block", height: "min(4.5vw, 18px)", width: "min(4.5vw, 18px)"}}>
                              <ExternalLinkSvg size={"min(4.5vw, 18px)"} color={"#999999"} />
                            </div>
                            <span style={{color: "#999999", textDecoration: "underline", textUnderlineOffset: "0.2em", textDecorationThickness: "0.12em", fontSize: "min(4.5vw, 18px)", marginInlineStart: 5, textAlign: "start", fontFamily: "Futura Medium"}}>
                              {"VIEW PROFILE IN NEW TAB"}
                            </span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                {`I. ${hasCompletedProfileOnboarding ? "EDIT YOUR LINK" : "CHOOSE YOUR LINK"}`}
              </div>
              <div
                style={{marginTop: "2vh", display: "flex", alignItems: "center", justifyContent: "space-between"}}
              >
                <div
                  className={"application-input"}
                  style={{display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "start"}}
                  onClick={() => {
                    this.inputRef && this.inputRef.focus()
                  }}
                >
                  <div disabled>
                    {"neurofit.coach/"}
                  </div>
                  <input
                    id="coach-username-input"
                    type="text"
                    value={username}
                    disabled={formFieldsDisabled}
                    autoCapitalize="true"
                    autoCorrect="false"
                    spellCheck={false}
                    ref={input => this.inputRef = input}
                    onChange={(e) => {
                      this.setState({...this.state, profileDetails: {...profileDetails, username: sanitizeUsername(e.target.value)}})
                    }}
                    onPaste={(e) => {
                      this.setState({...this.state, profileDetails: {...profileDetails, username: sanitizeUsername(e.target.value)}})
                    }}
                    placeholder={"yourname"}
                    className={"coach-username-input"}
                    style={{border: "none", fontFamily: "Futura", fontSize: "min(5vw, 22px)", padding: "0px"}}
                  />
                </div>
              </div>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                {`II. PROFILE IMAGE`}
              </div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book"}}>
                {`Upload a professional headshot as a square image file.`}
              </div>
              <div style={{display: "flex", justifyContent: "center", margin: "3vh"}}>
                <div style={{width: 160, height: 160, backgroundColor: "#FFFFFF", position: "relative", borderRadius: "50%", boxShadow: "0px 0px 1.5px 0.5px #A5A8B0"}}>
                  <div style={{
                    position: "absolute",
                    width: 150,
                    height: 150,
                    margin: 5,
                    overflow: "hidden",
                    borderRadius: "50%",
                    backgroundColor: "#CFD2DD"
                  }}>
                    <img for="photo-upload" style={{width: "100%", height: "100%", borderRadius: "50%", boxShadow: "0px 0px 0.5px 1px #A5A8B0"}} src={profile_image_url}/>
                  </div>
                  <input id="photo-upload" type="file" accept="image/jpg, image/jpeg, image/png, image/webp" style={{
                    opacity: 0,
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                    position: "absolute",
                    padding: 6,
                    cursor: "pointer"
                  }} onChange={e => {
                    e.preventDefault();
                    const reader = new FileReader();
                    const imageFile = e.target.files[0];

                    // TODO: Reject Image if not a square.
                    reader.onloadend = (e) => {
                      var img = document.createElement("img");
                      img.onload = (event) => {

                        if (img.height !== img.width) {
                          onFlashTopErrorMessage("Error: Image must be a square with equal width and height.")
                          return
                        }
                        function createHiDPICanvas(width, height) {
                            const ratio = 1//DevicePixelRatio;
                            var canvas = document.createElement("canvas");
                            canvas.width = width * ratio;
                            canvas.height = height * ratio;
                            canvas.style.width = width + "px";
                            canvas.style.height = height + "px";
                            canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);

                            return canvas;
                        }

                        // Dynamically create a canvas element
                        var canvas = createHiDPICanvas(ProfileImageSizePixels, ProfileImageSizePixels)

                        // var canvas = document.getElementById("canvas");
                        var ctx = canvas.getContext("2d");

                        // Actual resizing
                        ctx.drawImage(img, 0, 0, ProfileImageSizePixels, ProfileImageSizePixels);

                        // Show resized image in preview element
                        const resizedImageUrl = canvas.toDataURL(imageFile.type);

                        this.setState()

                        canvas.toBlob(
                          (blob) => {
                            const resizedImageFile = new File([blob], "profile_image.jpeg",{type:"image/jpeg", lastModified:new Date().getTime()})

                            this.setState({
                              profileImageDetails: {
                                profile_image_url: resizedImageUrl,
                                profile_image_file: resizedImageFile,
                                profile_image_version
                              },
                              showProfileImageBackgroundOverlayOnSuccess: true
                            })

                            onSaveUpdatedProfileImage(resizedImageFile)
                          },
                          "image/jpeg",
                          1.0,
                        );
                      };
                      img.src = e.target.result;
                    }
                    reader.readAsDataURL(imageFile);
                  }}/> 
                  <div style={{height: 150, width: 150, margin: 5, position: "absolute", pointerEvents: (saveProfileImageState === REQUEST_ERROR) ? "all" : "none"}}>
                    <div style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: showProfileImageDarkBackgroundOverlay ? "rgba(0, 0, 0, 0.3)" : "transparent",
                      transition: "background-color 150ms linear",
                      borderRadius: "50%",
                    }}>
                      <SwitchTransition mode="out-in">
                        <CSSTransition
                          timeout={500}
                          classNames={"hide-fade-in"}
                          unmountOnExit
                          addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                          }}
                          key={`${saveProfileImageState}`}
                        >
                          <div style={{
                            width: 30,
                            height: 30,
                            padding: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          >
                            {(saveProfileImageState === REQUEST_UNSTARTED) && (profile_image_version === 0) && (
                              <EditSvg size={30} color={"#FFFFFF"} dropShadowColor={"#A5A8B0"} />
                            )}
                            {saveProfileImageState === REQUEST_FETCHING && (
                              <OvalSpinnerSvg size={30} color={"#FFFFFF"} />
                            )}
                            {(saveProfileImageState === REQUEST_SUCCESS) && showProfileImageDarkBackgroundOverlay && (
                              <CompletionCheckIcon
                                checkColorComplete={"#FFFFFF"}
                                scaleFactor={1.5}
                                checkboxBaseClassName={"checkbox-request-button-black"}
                                isComplete
                                animateCheck
                              />
                            )}
                            {saveProfileImageState === REQUEST_ERROR && (
                              <RefreshSvg
                                size={30}
                                color={"#FFFFFF"}
                                dropShadowColor={"#A5A8B0"}
                                onClick={() => {
                                  onSaveUpdatedProfileImage(profile_image_file)
                                }}
                              />
                            )}
                          </div>
                        </CSSTransition>
                      </SwitchTransition>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                {`III. PROFILE DETAILS`}
              </div>
              <div style={{marginTop: "2vh", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <input
                  type="text"
                  style={{width: "calc(50% - 1vh - 16px)"}}
                  value={first_name}
                  disabled={formFieldsDisabled}
                  autoCapitalize="true"
                  autoCorrect="false"
                  autoComplete="given-name"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, first_name: e.target.value}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, first_name: e.target.value}})
                  }}
                  placeholder={"First Name"}
                  className={"application-input"}
                />
                <input
                  type="text"
                  style={{width: "calc(50% - 1vh - 16px)"}}
                  value={last_name}
                  disabled={formFieldsDisabled}
                  autoCapitalize="true"
                  autoCorrect="false"
                  autoComplete="family-name"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, last_name: e.target.value}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, last_name: e.target.value}})
                  }}
                  placeholder={"Last Name"}
                  className={"application-input"}
                />
              </div>
              <div style={{marginTop: "2vh"}}>
                <input
                  type="text"
                  value={email_address}
                  disabled={formFieldsDisabled}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, email_address: sanitizeEmailAddress(e.target.value)}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, email_address: sanitizeEmailAddress(e.target.value)}})
                  }}
                  placeholder={"Email Address"}
                  className={"application-input"}
                />
              </div>
              <Suspense fallback={
                <div style={{marginTop: "2vh"}}>  
                  <input
                    type="text"
                    value={""}
                    disabled
                    placeholder={"Loading Affiliate Payout Countries..."}
                    className={"application-input"}
                  />
                </div>
              }>
                <CountryDropdown
                  value={coach_location_country_code}
                  valueType={"short"}
                  onChange={(val) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, coach_location_country_code: val}})
                  }}
                  showDefaultOption
                  defaultOptionLabel="Your Location (Country)"
                  classes="application-input"
                  style={{
                    marginTop: "2vh",
                    width: "100%",
                    WebkitAppearance: "none",
                    appearance: "none",
                    color: !!(coach_location_country_code) ? "#000000" : "#BABDC6"
                  }}
                  priorityOptions={["US", "AU", "CA", "GB"]}
                />
              </Suspense>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                {`IV. COACHING DETAILS`}
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Your Occupation Title`}
                  <sup>*</sup>
                </div>
                <input
                  type="text"
                  value={occupation}
                  disabled={formFieldsDisabled}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, occupation: e.target.value.slice(0, 50)}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, occupation: e.target.value.slice(0, 50)}})
                  }}
                  placeholder={"Occupation (50 characters max)"}
                  className={"application-input"}
                />
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Years Of Experience`}
                  <sup>*</sup>
                </div>
                <input
                  type="number"
                  value={years_professional_experience}
                  disabled={formFieldsDisabled}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, years_professional_experience: sanitizeNumber(e.target.value)}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, years_professional_experience: sanitizeNumber(e.target.value)}})
                  }}
                  placeholder={"# Years Of Experience..."}
                  className={"application-input"}
                />
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Professional URL`}
                  <sup>*</sup>
                </div>
                <input
                  type="text"
                  value={personal_url}
                  disabled={formFieldsDisabled}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, personal_url: e.target.value}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, personal_url: e.target.value}})
                  }}
                  placeholder={"Your Professional URL..."}
                  className={"application-input"}
                />
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Coaching Niche Title`}
                  <sup>*</sup>
                </div>
                <TextFeedbackPrompt
                  disabled={formFieldsDisabled}
                  color="#000000"
                  inputBackgroundColor="#ffffff"
                  marginTop={"0px"}
                  marginBottom={"2vh"}
                  placeholderText={"Describe who you help and how you help them in 50 characters or less."}
                  value={coaching_niche_title}
                  onChange={val => {
                    this.setState({...this.state, profileDetails: {...profileDetails, coaching_niche_title: val}})
                  }}
                />
                <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (maxCoachingNicheTitleLengthExceeded) ? "#ff9999" : "#c1c4d2", transition: "color 250ms linear"}}>
                  {`${MaxTitleLength - coaching_niche_title.length} Characters Remaining`}
                </div>
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Coaching Niche Description`}
                  <sup>*</sup>
                </div>
                <TextFeedbackPrompt
                  disabled={formFieldsDisabled}
                  color="#000000"
                  inputBackgroundColor="#ffffff"
                  marginTop={"0px"}
                  marginBottom={"2vh"}
                  placeholderText={`Describe your niche, target audience, and services in 3-4 sentences...`}
                  value={coaching_niche_description}
                  onChange={val => {
                    this.setState({...this.state, profileDetails: {...profileDetails, coaching_niche_description: val}})
                  }}
                />
                <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (maxCoachingNicheLengthExceeded) ? "#ff9999" : "#c1c4d2", transition: "color 250ms linear"}}>
                  {!minCoachingNicheLengthMet ? `Add ${MinCoachingNicheDescriptionLength - coaching_niche_description.length} More Characters` : `${MaxCoachingNicheDescriptionLength - coaching_niche_description.length} Characters Remaining`}
                </div>
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Experience/Background Title`}
                  <sup>*</sup>
                </div>
                <TextFeedbackPrompt
                  disabled={formFieldsDisabled}
                  color="#000000"
                  inputBackgroundColor="#ffffff"
                  marginTop={"0px"}
                  marginBottom={"2vh"}
                  placeholderText={"Describe your background as a coach or wellness/health professional in 50 characters or less."}
                  value={background_experience_title}
                  onChange={val => {
                    this.setState({...this.state, profileDetails: {...profileDetails, background_experience_title: val}})
                  }}
                />
                <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (maxBackgroundExperienceTitleLengthExceeded) ? "#ff9999" : "#c1c4d2", transition: "color 250ms linear"}}>
                  {`${MaxTitleLength - background_experience_title.length} Characters Remaining`}
                </div>
              </div>
              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Experience/Background Description`}
                  <sup>*</sup>
                </div>
                <TextFeedbackPrompt
                  disabled={formFieldsDisabled}
                  color="#000000"
                  inputBackgroundColor="#ffffff"
                  marginTop={"0px"}
                  marginBottom={"2vh"}
                  placeholderText={"Describe your background + experience as a coach or wellness/health professional in 3-4 sentences..."}
                  value={background_experience_description}
                  onChange={val => {
                    this.setState({...this.state, profileDetails: {...profileDetails, background_experience_description: val}})
                  }}
                />
                <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingInlineEnd: 10, fontFamily: "Futura", fontSize: "min(4vw, 18px)", color: (maxBackgroundExperienceLengthExceeded) ? "#ff9999" : "#c1c4d2", transition: "color 250ms linear"}}>
                  {!minBackgroundExperienceLengthMet ? `Add ${MinCoachingNicheDescriptionLength - background_experience_description.length} More Characters` : `${MaxCoachingNicheDescriptionLength - background_experience_description.length} Characters Remaining`}
                </div>
              </div>
{/*              <div style={{marginTop: "2vh"}}>
                <div style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", textTransform: "uppercase", marginBottom: "1vh"}}>
                  {`Hourly 1-On-1 Rate (USD)`}
                  <sup>*</sup>
                </div>
                <input
                  type="currency"
                  value={(!!hourly_rate_usd_cents) ? ("$" + hourly_rate_usd_cents / 100) : null}
                  disabled={formFieldsDisabled}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, hourly_rate_usd_cents: sanitizeUsdCentsString(e.target.value)}})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, hourly_rate_usd_cents: sanitizeUsdCentsString(e.target.value)}})
                  }}
                  placeholder={"Minimum $100"}
                  className={"application-input"}
                />
              </div>*/}
{/*              <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "4vh"}}>
                <ConfirmSection
                  label={"Accept 1-on-1 session bookings"}
                  isConfirmed={is_client_session_booking_enabled}
                  onToggleConfirm={(val) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, is_client_session_booking_enabled: val}})
                  }}
                  isDisabled={formFieldsDisabled}
                  fontFamily={"Futura"}
                  marginTop={"0px"}
                  color={"#000000"}
                />
                <InfoSvg
                  color={"#000000"}
                  size={"min(4.5vw, 18px)"}
                  padding={"0px 0px 0.3em 0.4em"}
                  backgroundColor={"#FFFFFF"}
                  onClick={() => onShowAcceptBookingsExplanationModal()}
                />
              </div>
              <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "4vh"}}>
                <ConfirmSection
                  label={"Discounts for session bundles"}
                  isConfirmed={is_discounted_session_bundling_enabled}
                  onToggleConfirm={(val) => {
                    this.setState({...this.state, profileDetails: {...profileDetails, is_discounted_session_bundling_enabled: val}})
                  }}
                  isDisabled={formFieldsDisabled}
                  fontFamily={"Futura"}
                  marginTop={"0px"}
                  color={"#000000"}
                />
                <InfoSvg
                  color={"#000000"}
                  size={"min(4.5vw, 18px)"}
                  padding={"0px 0px 0.3em 0.4em"}
                  backgroundColor={"#FFFFFF"}
                  onClick={() => onShowDiscountedSessionBundlingExplanationModal()}
                />
              </div>*/}
              <div style={{display: "flex", justifyContent: "start", alignItems: "center", margin: "4vh 0vh 6vh 0vh"}}>
                <SendRequestButton
                  defaultContent={hasCompletedProfileOnboarding ? "Update Profile Link" : "Create Profile Link"}
                  loadingText={hasCompletedProfileOnboarding ? "Saving Updates..." : "Saving Profile..."}
                  successText={hasCompletedProfileOnboarding ? "Profile Updated" : "Profile Link Created"}
                  requestState={saveProfileLinkUpdatesRequestResult.state}
                  isDisabled={saveProfileUpdatesButtonDisabled}
                  dimButtonText={formIsIncomplete}
                  onClick={() => {
                    onSaveProfileLinkUpdates(profileDetails)
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!hasTabAccess && (
          <div style={{width: "85%", maxWidth: 1000}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`UNLOCK COACH PROFILE`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh"}}>
              {`Become a NEUROFIT-Certified Coach to create your own coach profile link and seamlessly attract client leads.`}
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"JOIN CERTIFICATION"}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProfileLinkTab