import React from 'react'

import DashboardSideMenuButton from './DashboardSideMenuButton'
import LoadingLogo from '../LoadingLogo'

import {
  ScheduleSvg,
  InboxSvg,
  EditProfileSvg,
  AffiliateSvg,
  GiftAppAccessSvg,
  SettingsSvg,
  LogOutSvg,
  SeoSvg,
  FaqSvg,
  FeatureRequestSvg,
  ContactSupportSvg,
  LegalSvg,
  AirplaneSvg,
  TargetPracticeSvg,
  ArtToolsSvg,
  LinkSvg
} from '../../../constants/dashboardSvgs'

import {
  NeuroFitCutoutSvg,
  BalanceSvg,
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
  LockSvg,
  ClearSvg,
} from '../../../constants/svgs'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../../constants/requestStates'

import {
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_BALANCE_TOOL,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_CLEAR_BUSINESS,
  DASHBOARD_SECTION_COACH_COPILOT,
  DASHBOARD_SECTION_COACH_TRAINING,
  DASHBOARD_SECTION_CONTENT_COPILOT,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
  DASHBOARD_SECTION_ADMIN_SEO,
} from '../../../constants/dashboardSections'

const SideBarWidth = 240

const DashboardSideMenu = ({
  isCertifiedTrainer,
  isCertifiedMasterCoach,
  isSeoAdmin,
  isBookingFeatureSetEnabled,
  selectedMenuSection,
  trainerFirstName,
  trainerLastName,
  trainerEmailAddress,
  onUpdateSelectedMenuSection,
  logoutRequestState,
  onLogoutUser
}) => {
  const logoutRequestButtonText = (logoutRequestState === REQUEST_FETCHING) ? "Logging Out..." : ((logoutRequestState === REQUEST_SUCCESS) ? "Logged Out." : "Log Out")
  const dashboardMenuItems = [
    {
      is_spacing: true,
    },
    {
      is_title: true,
      text: "Clients"
    },
    {
      title: "Clients",
      key: DASHBOARD_SECTION_CLIENTS,
      logo: (
        isCertifiedTrainer ? (
          <NeuroFitCutoutSvg size={"20px"} margin={"0.3em 0px 0px 0px"} lineColor={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#FFFFFF" : "#000000"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Sessions",
    //   key: DASHBOARD_SECTION_BOOKED_SESSIONS,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ScheduleSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Quiz Results",
      key: DASHBOARD_SECTION_QUIZ_LEADS,
      logo: (
        isBookingFeatureSetEnabled ? (
          <InboxSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      is_spacing: true,
    },
    // {
    //   is_title: true,
    //   text: "Coach"
    // },
    // {
    //   title: "Coach Copilot™",
    //   key: DASHBOARD_SECTION_COACH_COPILOT,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <AirplaneSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "Coach Training",
    //   key: DASHBOARD_SECTION_COACH_TRAINING,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <TargetPracticeSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    {
      is_title: true,
      text: "Business"
    },
    // {
    //   title: "Content Copilot",
    //   key: DASHBOARD_SECTION_CONTENT_COPILOT,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ArtToolsSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "CLEAR Business",
    //   key: DASHBOARD_SECTION_CLEAR_BUSINESS,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ClearSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_CLEAR_BUSINESS) ? "#000000" : "#999999"} />
    //     ) : (
    //       <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_CLEAR_BUSINESS) ? "#000000" : "#999999"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "BALANCE Tool",
    //   key: DASHBOARD_SECTION_BALANCE_TOOL,
    //   logo: (
    //     <BalanceSvg size={"20px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_BALANCE_TOOL) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    // {
    //   title: "Edit Profile",
    //   key: DASHBOARD_SECTION_EDIT_PROFILE,
    //   logo: (
    //     <EditProfileSvg size={"18px"} margin={"0.3em 0px 0.1em"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Profile Link",
      key: DASHBOARD_SECTION_EDIT_PROFILE,
      logo: (
        isBookingFeatureSetEnabled ? (
          <LinkSvg size={"20px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
        ) : (
          <LockSvg height={"20px"} width={"20px"} marginTop={"0.2em"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#000000" : "#999999"} />
        )
      ),
      onClick: () => {},
    },
    {
      title: "Affiliate",
      key: DASHBOARD_SECTION_AFFILIATE,
      logo: (
        <AffiliateSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_AFFILIATE) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    {
      title: "Gift App Access",
      key: DASHBOARD_SECTION_GIFT_APP_ACCESS,
      logo: (
        <GiftAppAccessSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_GIFT_APP_ACCESS) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    // {
    //   title: "Settings",
    //   key: DASHBOARD_SECTION_SETTINGS,
    //   logo: (
    //     <SettingsSvg size={"20px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_SETTINGS) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   is_spacing: true,
    // },
    {
      is_spacing: true,
    },
    {
      is_title: true,
      text: "Settings"
    },
    {
      title: "FAQ",
      key: DASHBOARD_SECTION_FAQ,
      logo: (
        <FaqSvg size={"18px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_FAQ) ? "#000000" : "#999999"} />
      ),
      onClick: () => {},
    },
    // {
    //   title: "Contact Support",
    //   key: DASHBOARD_SECTION_SUPPORT,
    //   logo: (
    //     <ContactSupportSvg size={"18px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_SUPPORT) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "Legal & Data",
    //   key: DASHBOARD_SECTION_LEGAL_AND_DATA,
    //   logo: (
    //     <LegalSvg size={"20px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_LEGAL_AND_DATA) ? "#000000" : "#999999"} />
    //   ),
    //   onClick: () => {},
    // },
    {
      title: logoutRequestButtonText,
      key: DASHBOARD_SECTION_LOGOUT,
      logo: (
        <LogOutSvg size={"15px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_LOGOUT) ? "#000000" : "#999999"} />
      ),
      onClick: () => onLogoutUser(),
    },
  ]

  return (
    <div style={{position: "fixed", overflowX: "hidden", overflowY: "scroll", top: 0, left: 0, bottom: 0, width: 240, backgroundColor: "#000000", zIndex: 10000}}>
      <div style={{padding: "8% 0px"}}>
        <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
          <div style={{display: "inline-block", padding: "0px 12%", width: "76%"}}>
            <div>
              <NeuroFitNamedLogoSvg
                logoSize={"min(4.5vw, 20px)"}
                color={"#FFFFFF"}
                onClick={() => window.location.reload(true/*hardReload*/)}
              />
            </div>
            <div style={{display: "flex", justifyContent: "start"}}>
              <div style={{display: "inline-block"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: 3}}>
                  <div style={{color: "#EDEEF2", textTransform: "uppercase", maxWidth: 180, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginInlineEnd: "0.2em", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}>
                    {`${trainerFirstName} ${trainerLastName}`}
                  </div>
                </div>
                {(isCertifiedTrainer) && (
                  <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: 3}}>
                    <div style={{color: "#EDEEF2", textTransform: "uppercase", maxWidth: 180, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginInlineEnd: "0.2em", fontFamily: "Futura", fontSize: "min(3.25vw, 14px)"}}>
                      {`CERTIFIED COACH`}
                    </div>
                    <VerifiedSvg size={"min(3.25vw, 14px)"} color={"#EDEEF2"} />
                  </div>
                )}
                <div style={{maxWidth: "100%", marginTop: 3, overflow: "hidden", maxWidth: 180, whiteSpace: "nowrap", textAlign: "left", textOverflow: "ellipsis", fontFamily: "Futura", color: "#999999", fontSize: "min(3.5vw, 15px)"}}>
                  {trainerEmailAddress}
                </div>
              </div>
            </div>
          </div>
        </div>
        {dashboardMenuItems.map((item, idx) => {
          if (item.is_spacing) {
            return (
              <div key={idx} style={{height: "4vh"}} />
            )
          } else if (item.is_title) {
            return (
              <div key={item.text} style={{width: "100%", padding: "0px 0px 1vh 12%", textTransform: "uppercase", fontFamily: "Futura Medium", color: "#BABDC6", fontSize: "min(3.5vw, 16px)"}}>
                {item.text}
              </div>
            )
          } else {
            return (
              <DashboardSideMenuButton
                key={idx}
                isSelected={selectedMenuSection === item.key}
                title={item.title}
                logo={item.logo}
                onClick={() => {
                  item.onClick()
                  onUpdateSelectedMenuSection(item.key)
                }}
              />
            )
          }
        })}
      </div>
    </div>
  )
}


export default DashboardSideMenu;