import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  getAbbreviatedDurationSinceTimestamp,
  getReadableDurationSinceTimestamp,
  formatReadableUnixTimestampMins,
  formatReadableUnixTimestamp,
  getDaysSinceTimestamp,
  getTimeOfDay,
  readableDate,
  MinLargeDesktopWidthPixels,
  ProductionCertificationUrl,
  ProductionCoachOSUrl,
} from '../../../utils'

import {
  getInsightForCheckInInsight,
  getInsightForSelfCare,
  getInsightForEmbodiment,
  getInsightForAvoidance,
  getInsightForOverallAverageStatePercentage,
  getInsightForLifeAspectAverageStatePercentage,
  getMetaForRecoveryScoreHomePage,
  getMetaForHeartRateVariability,

  getMetaForDysregulationQuizScore,
  getMetaForQuizSymptomResultsData,
  getMetaForQuizHabitResultsData,
  getMetaForQuizSelfCheckInResultsData,
  getTextColorForDysregulationQuizScore,
} from '../../../dashboardUtils'


import PendingResultsPanel from '../PendingResultsPanel'
import HabitBarChartInsightSection from '../HabitBarChartInsightSection'
import PieChartInsightSection from '../PieChartInsightSection'
import HrvResultsPanel from '../HrvResultsPanel'
import LineGraph from '../LineGraph'
import InlineClientTestimonial from '../InlineClientTestimonial'
import NumberStepper from '../NumberStepper'
import ConfirmSection from '../ConfirmSection'
import TextFeedbackPrompt from '../TextFeedbackPrompt'
import AutogenerateTextInput from '../AutogenerateTextInput'
import SendRequestButton from '../SendRequestButton'
import PercentageRing from '../PercentageRing'
import ProgramResultsPanel from '../ProgramResultsPanel'
import CompletionCheckIcon from '../CompletionCheckIcon'

import DiscoveryQuizResults from './DiscoveryQuizResults'

import {flag} from 'country-emoji'


import {
  ActivityArrowSvg,
  BackArrowSvg,
  SendArrowSvg,
  CopySvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
  TimerSvg,
} from '../../../constants/dashboardSvgs'



import { toPng, toBlob } from 'html-to-image';

import { saveAs } from 'file-saver';

const MaxLeadTrainerNotesLength = 1000

const formatPct = val => `${(val * 100).toFixed(0)}%`

const ClientTestimonialDomId = "client-testimonial"

const defaultState = {
  codeIsCopied: false,
  selectedLeadListView: "inbox",
}
const LeadViews = [
  {
    key: "inbox",
    value: "Inbox"
  },
  {
    key: "replied",
    value: "Replied"
  }
]

const LeadSubViews = [
  {
    key: "details",
    value: "Details"
  },
  {
    key: "settings",
    value: "Settings"
  }
]
class LeadsTab extends React.Component {
  constructor(props){
     super(props)
     this.state = defaultState
  }

  componentWillMount() {
    const {
      onLoadLeadList,
      onLoadLeadDetails,
      hasTabAccess,
      selectedLeadId,
    } = this.props

    if (hasTabAccess) {
      if (selectedLeadId !== undefined) {
        onLoadLeadDetails()
      } else {
        onLoadLeadList()
      }
    }
  }

  componentWillUnmount() {
    const {
      onResetLeadList,
      onResetLeadDetails,
      onResetGeneratedLeadResponseDraft,
      onResetSendClientLeadReplyRequest,
      onSelectLead,
    } = this.props
    onResetLeadList()
    onResetLeadDetails()
    onResetGeneratedLeadResponseDraft()
    onResetSendClientLeadReplyRequest()
  }

  render() {

    const {
      isDesktop,
      hasTabAccess,
      leadListRequestResult,
      onLoadLeadList,
      onSelectLead,
      selectedLeadId,
      selectedLeadSubview,
      onLoadLeadDetails,
      onNavigateToLeadSubview,
      onNavigateToProfileLinkTab,
      selectedLeadDetailsRequestResult,
      onSendClientLeadReply,
      onGenerateClientDiscoveryQuizResponseDraft,
      onResetSendClientLeadReplyRequest,
      sendClientLeadReplyRequestResult,
      removeClientLeadRequestResult,
      createClientLeadResponseDraftRequestResult,
      onRemoveClientLead,
      innerWidth
    } = this.props

    const {
      codeIsCopied,
      selectedLeadListView,
    } = this.state

    const disableCopyButton = codeIsCopied

    const timeOfDay = getTimeOfDay()

    const isDesktopWidth = innerWidth > MinLargeDesktopWidthPixels

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 20, display: "flex", width: "100%", justifyContent: "center"}}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={500}
            classNames={"hide-fade-in"}
            unmountOnExit
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            key={`${(leadListRequestResult.state !== REQUEST_SUCCESS && leadListRequestResult !== REQUEST_ERROR)}-${selectedLeadId}-${(selectedLeadDetailsRequestResult.state !== REQUEST_SUCCESS && selectedLeadDetailsRequestResult.state !== REQUEST_ERROR)}`}
          >
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              {!(selectedLeadId) ? (
                <div style={{width: "min(85%, 1000px)"}}>
                  {(leadListRequestResult.state === REQUEST_SUCCESS) && (
                    <div>
                      <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh"}}>
                        <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                          {`Quiz Results`}
                        </div>
                        <div
                          style={{display: "flex", cursor: "pointer", alignItems: "center"}}
                          onClick={() => onLoadLeadList()}
                        >
                          <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{isDesktop ? `UPDATED ${formatReadableUnixTimestampMins(leadListRequestResult.result.created_at)}` : "REFRESH"}</span>
                          <RefreshSvg
                            size={"min(4vw, 18px)"}
                            color={"#777777"}
                            onClick={() => {}}
                          />
                        </div>                   
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "start", marginBottom: "2vh"}}>
                        <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", overflowY: "visible", whiteSpace: "nowrap"}}>
                          {LeadViews.map(view => {
                            const isSelected = (view.key === selectedLeadListView)
                            return (
                              <div
                                key={view.key}
                                style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                                onClick={() => this.setState({selectedLeadListView: view.key})}
                              >
                                {view.value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <LeadList  
                          isDesktop={isDesktopWidth}
                          leadPreviews={(selectedLeadListView === "inbox") ? leadListRequestResult.result.current_lead_previews : leadListRequestResult.result.replied_lead_previews}
                          onSelectLead={leadId => {
                            onSelectLead(leadId)
                            onLoadLeadDetails()
                          }}
                          isInboxTab={(selectedLeadListView === "inbox")}
                          onNavigateToProfileLinkTab={() => onNavigateToProfileLinkTab()}
                          innerWidth={innerWidth}
                        />
                      </div>
                    </div>
                  )}
                  {(leadListRequestResult.state === REQUEST_FETCHING) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`LOADING QUIZ RESULTS...`}
                      </div>
                    </div>
                  )}
                  {(leadListRequestResult.state === REQUEST_ERROR) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`ERROR LOADING RESULTS`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                        {`There was an error loading your Discovery Quiz Results - please retry.`}
                      </div>
                      <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            onLoadLeadList()
                          }}
                        >
                          <span>
                            {"RETRY"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  {(leadListRequestResult.state === REQUEST_UNSTARTED) && (
                    <div style={{width: "85%", maxWidth: 1000}}>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`UNLOCK QUIZ RESULTS`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh"}}>
                        {`Become a NEUROFIT-Certified Coach to attract client leads on-demand with our pre-vetted Nervous System Discovery Quiz.`}
                      </div>
                      <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                          }}
                        >
                          <span>
                            {"JOIN CERTIFICATION"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{width: "min(100%, 1500px)", display: "flex", justifyContent: "center"}}>
                  {(selectedLeadDetailsRequestResult.state === REQUEST_SUCCESS) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{minWidth: "85%"}}>
                          <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh", marginBottom: "2vh"}}>
                            <div style={{fontSize: "min(5vw, 30px)", fontFamily: "Futura", textTransform: "uppercase", display: "flex", alignItems: "center"}}>
                              <div
                                onClick={() => {
                                  onLoadLeadList()
                                  onSelectLead(undefined)
                                  onResetSendClientLeadReplyRequest()
                                }}
                                style={{cursor: "pointer", display: "inline-block", color: "#777777"}}
                              >
                                {`Quiz`}
                              </div>
                              <BackArrowSvg size={"min(9vw, 40px)"} color={"#777777"} rotation={180} />
                              <div style={{display: "inline-block", maxWidth: "50vw", overflow: "hidden", textTransform: "uppercase", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                                {"Results"}
                              </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                              {isDesktopWidth && (
                                <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{`UPDATED ${formatReadableUnixTimestampMins(selectedLeadDetailsRequestResult.result.created_at)}`}</span>
                              )}
                              <RefreshSvg
                                size={"min(4vw, 18px)"}
                                color={"#777777"}
                                onClick={() => onLoadLeadDetails()}
                              />
                            </div>                   
                          </div>
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "start"}}>
                        <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", paddingInlineEnd: "7.5%", paddingInlineStart: "7.5%", overflowY: "visible", whiteSpace: "nowrap"}}>
                          {LeadSubViews.map(view => {
                            const isSelected = (view.key === selectedLeadSubview)
                            return (
                              <div
                                key={view.key}
                                style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                                onClick={() => onNavigateToLeadSubview(view.key)}
                              >
                                {view.value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <SwitchTransition mode="out-in">
                          <CSSTransition
                            timeout={500}
                            classNames={"hide-fade-in"}
                            unmountOnExit
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            key={`${selectedLeadSubview}`}
                          >
                            <div>
                              {(selectedLeadSubview === "details") && (
                                <ClientQuizLeadDetails
                                  timeOfDay={timeOfDay}
                                  isDesktopWidth={isDesktopWidth}
                                  sendClientLeadReplyRequestResult={sendClientLeadReplyRequestResult}
                                  leadDetailsObject={selectedLeadDetailsRequestResult.result && selectedLeadDetailsRequestResult.result.client_lead_details}
                                  onSendClientLeadReply={requestObject => onSendClientLeadReply(requestObject)}
                                  onGenerateClientDiscoveryQuizResponseDraft={() => onGenerateClientDiscoveryQuizResponseDraft()}
                                  createClientLeadResponseDraftRequestResult={createClientLeadResponseDraftRequestResult}
                                />
                              )}
                              {(selectedLeadSubview === "settings") && (
                                <div style={{width: "85%", maxWidth: 500, fontFamily: "Futura"}}>
                                  <RemoveClientLead
                                    leadDetailsObject={selectedLeadDetailsRequestResult.result && selectedLeadDetailsRequestResult.result.client_lead_details}
                                    removeClientLeadRequestResult={removeClientLeadRequestResult}
                                    onRemoveClientLead={() => onRemoveClientLead()}
                                    onNavigateToLeadsView={() => {
                                      onLoadLeadList()
                                      onSelectLead(undefined)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </CSSTransition>
                        </SwitchTransition>
                      </div>
                    </div>
                  )}
                  {(selectedLeadDetailsRequestResult.state === REQUEST_FETCHING) && (
                  <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`LOADING RESULTS...`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(selectedLeadDetailsRequestResult.state === REQUEST_ERROR) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`ERROR LOADING DETAILS`}
                            </div>
                            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                              {`There was an error loading the quiz result details - please retry.`}
                            </div>
                            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                              <button
                                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                                onClick={() => {
                                  onLoadLeadDetails()
                                }}
                              >
                                <span>
                                  {"RETRY"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    )
  }
}

const LeadList = ({leadPreviews, isDesktop, onSelectLead, onNavigateToProfileLinkTab, isInboxTab, innerWidth}) => (
  <div>
    <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
      <div style={{backgroundColor: "#fbfbfc", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(3vw, 14px)", padding: "min(3.5vw, 16px)"}}>
        <div style={{width: "40%", display: "inline-block"}}>
          {"QUIZ RESULTS"}
        </div>
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"OVERALL"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"SYMPTOMS"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"HABITS"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
            {"CHECK-IN"}
          </div>
        )}
      </div>
      {leadPreviews.map(preview => (
        <div key={preview.id} style={{width: "100%", borderTop: "1px #dddddd solid", fontSize: "min(4vw, 18px)", fontFamily: "Futura"}}>
          <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
            <div style={{width: isDesktop ? "40%" : "100%", display: "inline-block"}}>
              <span onClick={() => onSelectLead(preview.id)} style={{textDecoration: "underline", fontSize: "min(4.5vw, 20px)", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.client_first_name}
              </span>
              <div style={{color: "#777777", fontSize:" min(3vw, 15px)", overflow: "hidden", margin: "min(1.75vw, 8px) 0px", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.client_email_address}
              </div>
              <div style={{color: "#000000", fontSize:" min(2.5vw, 12px)", fontFamily: "Futura Medium", textTransform: "uppercase", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center"}}>
                <span>{`Received ${getAbbreviatedDurationSinceTimestamp(preview.created_at_unix)} Ago`}</span>
              </div>
            </div>
            {isDesktop && (
              <div style={{width: "15%", display: "flex", height: 50, justifyContent: "flex-end"}}>
                <PercentageRing
                  sizeVw={10}
                  maxSizePixels={50}
                  progressPercentage={preview.total_quiz_score * 100}
                  ringColor={getTextColorForDysregulationQuizScore(preview.total_quiz_score * 100)}
                  backgroundRingColor={"#EDEEF2"}
                  isInProgress
                  innerWidth={innerWidth}
                />
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "flex", height: 50, justifyContent: "flex-end"}}>
                <PercentageRing
                  sizeVw={10}
                  maxSizePixels={50}
                  progressPercentage={preview.symptom_total_score * 100}
                  ringColor={getTextColorForDysregulationQuizScore(preview.symptom_total_score * 100)}
                  backgroundRingColor={"#EDEEF2"}
                  isInProgress
                  innerWidth={innerWidth}
                />
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "flex", height: 50, justifyContent: "flex-end"}}>
                <PercentageRing
                  sizeVw={10}
                  maxSizePixels={50}
                  progressPercentage={preview.habit_total_score * 100}
                  ringColor={getTextColorForDysregulationQuizScore(preview.habit_total_score * 100)}
                  backgroundRingColor={"#EDEEF2"}
                  isInProgress
                  innerWidth={innerWidth}
                />
              </div>
            )}
            {isDesktop && (
              <div style={{width: "15%", display: "flex", height: 50, justifyContent: "flex-end"}}>
                <PercentageRing
                  sizeVw={10}
                  maxSizePixels={50}
                  progressPercentage={preview.life_aspects_regulation_total_score * 100}
                  ringColor={getTextColorForDysregulationQuizScore(preview.life_aspects_regulation_total_score * 100)}
                  backgroundRingColor={"#EDEEF2"}
                  isInProgress
                  innerWidth={innerWidth}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <div style={{width: "100%", backgroundColor: "#fbfbfc", color: (leadPreviews.length > 0) ? "#999999" : "#777777", borderTop: "1px #dddddd solid", fontSize: "min(3.75vw, 16px)", fontFamily: "Futura Book"}}>
        <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
          {(leadPreviews.length > 0) ?
            "Click on a client's name to view lead details." : (
              (isInboxTab ? (
                <span>
                  {"Receive your first lead by sharing your "}
                  <span style={{fontFamily: "Futura", textDecoration: "underline", cursor: "pointer"}} onClick={() => onNavigateToProfileLinkTab()}>
                    {"coach profile link"}
                  </span>
                  {"."}
                </span>
              ) : (
                <span>
                  {"Reply to leads via the lead details view."}
                </span>
              ))
          )}
        </div>
      </div>
    </div>
  </div>
)


const defaultLeadDetailsState = {
  trainer_response_text: "",
  client_lead_id: null,
  include_trainer_in_email_response: true
}

class ClientQuizLeadDetails extends React.Component {
  constructor(props){
     super(props)
     this.state = defaultLeadDetailsState
  }

  componentWillMount() {
    const {
      leadDetailsObject,
    } = this.props

    if (leadDetailsObject.trainer_response_sent) {
      this.setState({
        trainer_response_text: leadDetailsObject.trainer_response_text,
        client_lead_id: leadDetailsObject.id,
        include_trainer_in_email_response: leadDetailsObject.include_trainer_in_email_response
      })
    } else {
      this.setState({
        client_lead_id: leadDetailsObject.id,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const currentDraftResponseRequestResult = this.props.createClientLeadResponseDraftRequestResult
    if ((prevProps.createClientLeadResponseDraftRequestResult.state === REQUEST_FETCHING) && (currentDraftResponseRequestResult.state === REQUEST_SUCCESS)) {
      this.setState({trainer_response_text: currentDraftResponseRequestResult.result.draft_response_text})
    }
  }



  render() {
    const {
      leadDetailsObject,
      sendClientLeadReplyRequestResult,
      createClientLeadResponseDraftRequestResult,
      onSendClientLeadReply,
      onGenerateClientDiscoveryQuizResponseDraft,
      timeOfDay,
      hasTabAccess,
      isDesktopWidth
    } = this.props
    const {
      include_trainer_in_email_response,
      trainer_response_text,
      completed_lead_count
    } = this.state

    const isLeadReplied = leadDetailsObject.trainer_response_sent

    const updateFormDisabled = (sendClientLeadReplyRequestResult.state === REQUEST_FETCHING) || isLeadReplied

    const sendReplyButtonDisabled = updateFormDisabled || (trainer_response_text.length > MaxLeadTrainerNotesLength)

    const isWriteWithAiButtonDisabled = updateFormDisabled


    return (
      <div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 1500px)", fontFamily: "Futura"}}>
            <div style={{marginTop: "8vh"}}>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                {`I. PERSONAL DETAILS`}
              </div>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 1500px)", fontFamily: "Futura"}}>
            <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", maxWidth: 500, marginTop: "2vh"}}>
              <div style={{backgroundColor: "#fbfbfc", borderTop: "1px #dddddd solid", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
                <div>
                  {"NAME"}
                </div>
              </div>
              <div style={{fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium"}}>
                {`${leadDetailsObject.client_first_name}`}
              </div>
              <div style={{backgroundColor: "#fbfbfc", borderTop: "1px #dddddd solid", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
                <div>
                  {"EMAIL ADDRESS"}
                </div>
              </div>
              <div style={{fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium"}}>
                {`${leadDetailsObject.client_email_address}`}
              </div>
              <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
                <div>
                  {"QUIZ COMPLETION TIME"}
                </div>
              </div>
              <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                <span>{`🕒 ${getReadableDurationSinceTimestamp(leadDetailsObject.created_at_unix)}`}</span>
              </div>
              <div style={{backgroundColor: "#fbfbfc", color: "#999999", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", fontSize: "min(4vw, 18px)", padding: "min(3.5vw, 16px)"}}>
                <div>
                  {"OVERALL REGULATION SCORE"}
                </div>
              </div>
              <div style={{fontSize: "min(4vw, 18px)", color: "#000000", borderTop: "1px #dddddd solid", fontFamily: "Futura Medium", padding: "min(3.5vw, 16px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                <span>{`${(leadDetailsObject.total_quiz_score * 100).toFixed(0)}%`}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 1500px)", fontFamily: "Futura"}}>
            <div style={{marginTop: "8vh"}}>
              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                {`II. QUIZ RESULTS`}
              </div>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 1500px)", fontFamily: "Futura"}}>
            <DiscoveryQuizResults
              isCompleted
              hideTitle
              hideTotalQuizScore
              firstName={leadDetailsObject.client_first_name}
              timeOfDay={timeOfDay}
              quizResultObject={leadDetailsObject}
              isDesktopWidth={isDesktopWidth}
            />
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 1500px)", fontFamily: "Futura"}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "10vh", marginBottom: "2vh"}}>
              {`III. YOUR RESPONSE`}
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: "min(85%, 600px)", fontFamily: "Futura"}}>
            <div style={{marginTop: "4vh"}}>
              <AutogenerateTextInput
                title={""}
                placeholderText={`Write a few sentences responding to ${leadDetailsObject.client_first_name}'s results...`}
                value={trainer_response_text}
                formFieldsDisabled={updateFormDisabled}
                autogenerateTextRequestState={createClientLeadResponseDraftRequestResult.state}
                onChange={val => {
                  this.setState({...this.state, trainer_response_text: val})
                }}
                showEditableFields={!isLeadReplied}
                maxCharacterLimit={MaxLeadTrainerNotesLength}
                isWriteWithAIAvailable
                onTriggerWriteWithAIRequest={() => {
                  onGenerateClientDiscoveryQuizResponseDraft()
                }}
              />
            </div>
{/*            <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "4vh"}}>
              <ConfirmSection
                label={"Add me to the email thread"}
                isConfirmed={include_trainer_in_email_response}
                onToggleConfirm={(val) => {
                  this.setState({...this.state, include_trainer_in_email_response: val})
                }}
                isDisabled={updateFormDisabled}
                fontFamily={"Futura Medium"}
                marginTop={"0px"}
                color={"#000000"}
              />
            </div>*/}
            <div style={{minHeight: 100}}>
              {isLeadReplied ? (
                <ProgramResultsPanel
                  backgroundColor={"#FFFFFF"}
                  marginBottom={"4vh"}
                  title={"EMAIL RESPONSE SENT"}
                  description={`We've sent your response to ${leadDetailsObject.client_first_name} 🏆 Further edits are disabled.`}
                />
              ) : (
                <div>
                  <div style={{fontSize: "min(4.5vw, 20px)", fontFamily: "Futura Book", paddingTop: "4vh"}}>
                    {`Your response will be sent to ${leadDetailsObject.client_first_name} as an email, and you'll be added to the thread.`}
                  </div>
                </div>
              )}
            </div>
            <div style={{display: "flex", justifyContent: "start", alignItems: "center", margin: "4vh 0vh 6vh 0vh"}}>
              <SendRequestButton
                defaultContent={"Send Email Reply"}
                loadingText={"Sending Reply..."}
                successText={"Reply Sent"}
                requestState={isLeadReplied ? REQUEST_SUCCESS : sendClientLeadReplyRequestResult.state}
                isDisabled={updateFormDisabled || sendReplyButtonDisabled}
                onClick={() => {
                  const requestBody = {...this.state}
                  onSendClientLeadReply(requestBody)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class RemoveClientLead extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      removeConfirmationValue: ""
    }
  }

  render() {

    const {
      leadDetailsObject,
      removeClientLeadRequestResult,
      onRemoveClientLead,
      onNavigateToLeadsView,
    } = this.props

    const {removeConfirmationValue} = this.state

    const removeClientLeadPlanButtonDisabled = (removeConfirmationValue !== "remove") || (removeClientLeadRequestResult.state === REQUEST_FETCHING) || (removeClientLeadRequestResult.state === REQUEST_SUCCESS)

    return (
      <div style={{backgroundColor: "#FFFFFF", margin: "6vh 0px", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
        <div style={{padding: "min(5vw, 22px)"}}>
          <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
            {"REMOVE LEAD"}
          </div>
          {!leadDetailsObject.trainer_response_sent ? (
            <div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura", marginTop: "2vh"}}>
                {`Not the right client/coach fit? Remove this lead here.`}
              </div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Medium", marginTop: "2vh"}}>
                {`Please Note: Removing a lead cannot be undone.`}
              </div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura",  marginTop: "2vh"}}>
                {`To confirm, please enter the word "remove" in the input below:`}
              </div>
              <div style={{marginTop: "2vh"}}>
                <input
                  type="text"
                  value={removeConfirmationValue}
                  autoCapitalize="none"
                  autoCorrect="false"
                  spellCheck={false}
                  onChange={(e) => {
                    this.setState({...this.state, removeConfirmationValue: e.target.value.toLowerCase()})
                  }}
                  onPaste={(e) => {
                    this.setState({...this.state, removeConfirmationValue: e.target.value.toLowerCase()})
                  }}
                  style={{backgroundColor: "#fbfbfc"}}
                  placeholder={`Type "remove" to confirm...`}
                  className={"application-input"}
                />
              </div>
              <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: removeClientLeadPlanButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                  onClick={() => {
                    onRemoveClientLead()
                  }}
                  disabled={removeClientLeadPlanButtonDisabled}
                >
                  <span>
                    {(removeClientLeadRequestResult.state === REQUEST_SUCCESS) ? "LEAD REMOVED." : ((removeClientLeadRequestResult.state === REQUEST_FETCHING) ? "REMOVING LEAD..." : "REMOVE THIS LEAD")}
                  </span>
                </button>
              </div>
            </div>

          ) : (
            <div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book",  marginTop: "2vh"}}>
                {`This Lead has received a reply - as a result, it cannot be edited any further.`}
              </div>
              <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                  onClick={() => {
                    onNavigateToLeadsView()
                  }}
                >
                  <span>
                    {"BACK TO LEADS"}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}


export default LeadsTab