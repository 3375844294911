import React, { Suspense } from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import TopErrorMessage from '../common/TopErrorMessage'
import MembershipFeatureDescription from '../common/MembershipFeatureDescription'

import { history } from '../../history'

import {
  AppleSvg,
  GoogleSvg,
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

import {
  REQ_ACTION__FLASH_TOP_ERROR_MESSAGE,
  REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE,
  REQ_DASHBOARD_CREATE_ACCOUNT,
} from '../../constants/requestTypes'

import {
  FORM_FIELD_FIRST_NAME,
  FORM_FIELD_LAST_NAME,
  FORM_FIELD_OCCUPATION,
  FORM_FIELD_PROFESSIONAL_URL,
  FORM_FIELD_AFFILIATE_CODE,
  FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY,
  FORM_FIELD_EMAIL_ADDRESS,
  FORM_FIELD_PASSWORD,
  FORM_FIELD_SHOW_CREATE_ACCOUNT_ERROR_MESSAGE,
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,
} from '../../constants/formFields'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../../constants/requestStates';

import {
  baseAdminAppUrl,
  subdomainUrls,
  recordSignupFunnelEventGA,
  sanitizeEmailAddress,
  sanitizeWebsiteAddress,
  sanitizeAffiliateCode,
} from '../../utils'

import {
  MinPasswordLength,
} from '../../appUtils'

import {
  isAppleAuthEnabled,
  isGoogleAuthEnabled,
} from '../../constants/minVersionFeatures'


import {
  GA_LABEL_ENTERED_FIRST_NAME,
  GA_LABEL_ENTERED_EMAIL_ADDRESS,
  GA_LABEL_ENTERED_PASSWORD,
  GA_LABEL_CLICKED_CREATE_ACCOUNT,
  GA_LABEL_CLICKED_CREATE_ACCOUNT_APPLE,
  GA_LABEL_CLICKED_CREATE_ACCOUNT_GOOGLE,
} from '../../constants/gaEventLabels'

const CountryDropdown = React.lazy(() => import('../common/CountryDropdown'));

const MAX_OCCUPATION_LENGTH = 50

const buttonIconStyle = {
  display: "inline-block",
  width: 26,
  height: 26,
  maxHeight: 26,
  maxWidth: 26,
  padding: 2,
  marginInlineEnd: 10
}

const subHeaderStyle = {
  color: "#dddddd",
  fontSize: "min(3vh, 20px)",
  fontFamily: "Nunito",
  fontWeight: 600,
  textAlign: "start"
}

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const SignupPage = ({

  safeAreaInsets,
  innerHeight,
  formFields,
  requestResults,
  countryCode,
  isIOSNative,
  isAndroidNative,
  currentAppVersion,

  onUpdateFormField,
  onUpdateRequestState,
  onTriggerRequest

}) => {

  const firstName = formFields[FORM_FIELD_FIRST_NAME]
  const lastName = formFields[FORM_FIELD_LAST_NAME]
  const payoutsDestinationCountry = formFields[FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY]
  const emailAddress = formFields[FORM_FIELD_EMAIL_ADDRESS]
  const password = formFields[FORM_FIELD_PASSWORD]

  const topMessageValue = formFields[FORM_FIELD_TOP_ERROR_MESSAGE_VALUE]
  const showTopMessage = formFields[FORM_FIELD_SHOW_TOP_ERROR_MESSAGE]

  const topSuccessMessageValue = formFields[FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE]
  const showTopSuccessMessage = formFields[FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE]

  const createAccountResult = requestResults[REQ_DASHBOARD_CREATE_ACCOUNT]
  const creatingAccount = (createAccountResult.state === REQUEST_FETCHING)
  const createAccountButtonDisabled = (!(firstName) || !(lastName) || !(payoutsDestinationCountry) || !(emailAddress) || !(password) || (password.length < MinPasswordLength) || creatingAccount)
  const createAccountButtonText = (createAccountResult.state == REQUEST_FETCHING) ? "CREATING ACCOUNT..." : ((createAccountResult.state == REQUEST_SUCCESS) ? "CREATED ACCOUNT." : "CREATE ACCOUNT")
  return (
    <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
      <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
      <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
        <NeuroFitNamedLogoSvg
          logoSize={"min(4.25vw, 18px)"}
          color={"#000000"}
          onClick={() => history.push("/")}
        />
        <button
          onClick={() => history.push("/login")}
          className={"header-button"}
        >
          {"LOG IN"}
        </button>
      </div>
      <div 
        className={"disable-scrollbars"}
        style={containerStyle}
      >
        <div style={{textAlign: "start", width: "100vw"}}>
          <div style={{backgroundColor: "#EDEEF2",  padding: "10vh 0px 10vh 0px", borderTop: "solid black 2px"}}>
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 650, color: "#000"}}>
                <div style={{backgroundColor: "#EDEEF2", padding: "min(24px, max(3vw, 16px))"}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"WELCOME TO THE NEUROFIT DASHBOARD"}
                  </div>
                  <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    {"Manage your NEUROFIT App affiliate profile + payouts, and optimize client results with data-driven insights."}
                  </div>
                  <div style={{marginTop: "2vh", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <input
                      type="text"
                      style={{width: "calc(50% - 1vh - 16px)"}}
                      value={firstName}
                      disabled={creatingAccount}
                      autoCapitalize="true"
                      autoCorrect="false"
                      autoComplete="given-name"
                      spellCheck={false}
                      onChange={(e) => {
                        onUpdateFormField(FORM_FIELD_FIRST_NAME, e.target.value)
                      }}
                      onPaste={(e) => {
                        onUpdateFormField(FORM_FIELD_FIRST_NAME, e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!createAccountButtonDisabled)) {
                          recordSignupFunnelEventGA(GA_LABEL_CLICKED_CREATE_ACCOUNT)
                          onTriggerRequest(REQ_DASHBOARD_CREATE_ACCOUNT)
                        }
                      }}
                      placeholder={"First Name"}
                      className={"application-input"}
                    />
                    <input
                      type="text"
                      style={{width: "calc(50% - 1vh - 16px)"}}
                      value={lastName}
                      disabled={creatingAccount}
                      autoCapitalize="true"
                      autoCorrect="false"
                      autoComplete="family-name"
                      spellCheck={false}
                      onChange={(e) => {
                        onUpdateFormField(FORM_FIELD_LAST_NAME, e.target.value)
                      }}
                      onPaste={(e) => {
                        onUpdateFormField(FORM_FIELD_LAST_NAME, e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!createAccountButtonDisabled)) {
                          recordSignupFunnelEventGA(GA_LABEL_CLICKED_CREATE_ACCOUNT)
                          onTriggerRequest(REQ_DASHBOARD_CREATE_ACCOUNT)
                        }
                      }}
                      placeholder={"Last Name"}
                      className={"application-input"}
                    />
                  </div>
                  <Suspense fallback={
                    <div style={{marginTop: "2vh"}}>  
                      <input
                        type="text"
                        value={""}
                        disabled
                        placeholder={"Loading Affiliate Payout Countries..."}
                        className={"application-input"}
                      />
                    </div>
                  }>
                    <CountryDropdown
                      value={payoutsDestinationCountry}
                      valueType={"short"}
                      onChange={(val) => {
                        onUpdateFormField(FORM_FIELD_PAYOUTS_DESTINATION_COUNTRY, val)
                      }}
                      showDefaultOption
                      defaultOptionLabel="Bank Location (Affiliate Payouts)"
                      classes="application-input"
                      style={{
                        marginTop: "2vh",
                        width: "100%",
                        WebkitAppearance: "none",
                        appearance: "none",
                        color: !!(payoutsDestinationCountry) ? "#000000" : "#BABDC6"
                      }}
                      priorityOptions={["US", "AU", "CA", "GB"]}
                    />
                  </Suspense>
                  <div style={{marginTop: "2vh"}}>
                    <input
                      type="text"
                      value={emailAddress}
                      disabled={creatingAccount}
                      autoCapitalize="none"
                      autoCorrect="false"
                      autoComplete="email"
                      spellCheck={false}
                      onChange={(e) => {
                        onUpdateFormField(FORM_FIELD_EMAIL_ADDRESS, sanitizeEmailAddress(e.target.value))
                      }}
                      onPaste={(e) => {
                        onUpdateFormField(FORM_FIELD_EMAIL_ADDRESS, sanitizeEmailAddress(e.target.value))
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!createAccountButtonDisabled)) {
                          recordSignupFunnelEventGA(GA_LABEL_CLICKED_CREATE_ACCOUNT)
                          onTriggerRequest(REQ_DASHBOARD_CREATE_ACCOUNT)
                        }
                      }}
                      placeholder={"Email Address"}
                      className={"application-input"}
                    />
                  </div>
                  <div style={{marginTop: "2vh"}}>
                    <input
                      type="password"
                      value={password}
                      disabled={creatingAccount}
                      autoCapitalize="true"
                      autoCorrect="false"
                      autoComplete="new-password"
                      spellCheck={false}
                      onChange={(e) => {
                        onUpdateFormField(FORM_FIELD_PASSWORD, e.target.value)
                      }}
                      onPaste={(e) => {
                        onUpdateFormField(FORM_FIELD_PASSWORD, e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!createAccountButtonDisabled)) {
                          recordSignupFunnelEventGA(GA_LABEL_CLICKED_CREATE_ACCOUNT)
                          onTriggerRequest(REQ_DASHBOARD_CREATE_ACCOUNT)
                        }
                      }}
                      placeholder={"Set a Password (8+ characters)"}
                      className={"application-input"}
                    />
                  </div>
                  <button
                    style={{display: "block", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(5.65vw, 25px)", marginTop: "2vh", color: createAccountButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "15px 18px", border: "none"}}
                    disabled={createAccountButtonDisabled}
                    onClick={() => {
                      recordSignupFunnelEventGA(GA_LABEL_CLICKED_CREATE_ACCOUNT)
                      onTriggerRequest(REQ_DASHBOARD_CREATE_ACCOUNT)
                    }}
                  >
                    {createAccountButtonText}
                  </button>
                  <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "2vh"}}>
                    <span style={{marginInlineEnd: "0.2em"}}>{"By creating an account, you agree to our"}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                    <span style={{marginInlineEnd: "0.2em"}}>{", "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                    <span style={{marginInlineEnd: "0.2em"}}>{" & "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#cookies`} target="_blank" rel="noopener noreferrer nofollow">{"Cookie Policy"}</a>
                    <span>{". Thank you!"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopErrorMessage
        show={showTopMessage}
        message={topMessageValue}
        safeAreaInsets={safeAreaInsets}
      />
    </div>
  )
}

export default SignupPage;