import React from 'react'

import DashboardTopMenuButton from './DashboardTopMenuButton'
import LoadingLogo from '../LoadingLogo'

import {
  ScheduleSvg,
  EditProfileSvg,
  AffiliateSvg,
  AirplaneSvg,
  TargetPracticeSvg,
  GiftAppAccessSvg,
  SettingsSvg,
  LogOutSvg,
  FaqSvg,
  FeatureRequestSvg,
  ContactSupportSvg,
  ArtToolsSvg,
  InboxSvg,
  LegalSvg,
  LinkSvg,
} from '../../../constants/dashboardSvgs'

import {
  NeuroFitCutoutSvg,
  BalanceSvg,
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
  LockSvg,
  ClearSvg,
} from '../../../constants/svgs'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../../constants/requestStates'

import {
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_COACH_COPILOT,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_BALANCE_TOOL,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_COACH_TRAINING,
  DASHBOARD_SECTION_CONTENT_COPILOT,
  DASHBOARD_SECTION_CLEAR_BUSINESS,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
} from '../../../constants/dashboardSections'

const DashboardSideMenu = ({
  isDesktop,
  isCertifiedTrainer,
  isBookingFeatureSetEnabled,
  selectedMenuSection,
  trainerFirstName,
  trainerLastName,
  onUpdateSelectedMenuSection,
  logoutRequestState,
  onLogoutUser
}) => {
  const logoutRequestButtonText = (logoutRequestState === REQUEST_FETCHING) ? "Logging Out..." : ((logoutRequestState === REQUEST_SUCCESS) ? "Logged Out." : "Log Out")
  const dashboardMenuItems = [
    {
      title: "Clients",
      key: DASHBOARD_SECTION_CLIENTS,
      logo: (
        isCertifiedTrainer ? (
          <NeuroFitCutoutSvg size={"20px"} margin={"0.3em 0px 0px 0px"} lineColor={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#000000" : "#000000"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#FFFFFF" : "#707070"} />
        ) : (
          <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_CLIENTS) ? "#FFFFFF" : "#707070"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Sessions",
    //   key: DASHBOARD_SECTION_BOOKED_SESSIONS,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ScheduleSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#FFFFFF" : "#707070"} />
    //     ) : (
    //       <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_BOOKED_SESSIONS) ? "#FFFFFF" : "#707070"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Quiz",
      key: DASHBOARD_SECTION_QUIZ_LEADS,
      logo: (
        isBookingFeatureSetEnabled ? (
          <InboxSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#FFFFFF" : "#707070"} />
        ) : (
          <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_QUIZ_LEADS) ? "#FFFFFF" : "#707070"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Copilot",
    //   key: DASHBOARD_SECTION_COACH_COPILOT,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <AirplaneSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#FFFFFF" : "#707070"} />
    //     ) : (
    //       <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_COPILOT) ? "#FFFFFF" : "#707070"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "Training",
    //   key: DASHBOARD_SECTION_COACH_TRAINING,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <TargetPracticeSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#FFFFFF" : "#707070"} />
    //     ) : (
    //       <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_COACH_TRAINING) ? "#FFFFFF" : "#707070"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Profile",
      key: DASHBOARD_SECTION_EDIT_PROFILE,
      logo: (
        isBookingFeatureSetEnabled ? (
          <LinkSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#FFFFFF" : "#707070"} />
        ) : (
          <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_EDIT_PROFILE) ? "#FFFFFF" : "#707070"} />
        )
      ),
      onClick: () => {},
    },
    // {
    //   title: "Content",
    //   key: DASHBOARD_SECTION_CONTENT_COPILOT,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ArtToolsSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#FFFFFF" : "#707070"} />
    //     ) : (
    //       <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_CONTENT_COPILOT) ? "#FFFFFF" : "#707070"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    // {
    //   title: "CLEAR",
    //   key: DASHBOARD_SECTION_CLEAR_BUSINESS,
    //   logo: (
    //     isBookingFeatureSetEnabled ? (
    //       <ClearSvg size={"22px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_CLEAR_BUSINESS) ? "#FFFFFF" : "#707070"} />
    //     ) : (
    //       <LockSvg height={"22px"} width={"20px"} marginTop={"3px"} color={(selectedMenuSection === DASHBOARD_SECTION_CLEAR_BUSINESS) ? "#FFFFFF" : "#707070"} />
    //     )
    //   ),
    //   onClick: () => {},
    // },
    {
      title: "Affiliate",
      key: DASHBOARD_SECTION_AFFILIATE,
      logo: (
        <AffiliateSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_AFFILIATE) ? "#FFFFFF" : "#707070"} />
      ),
      onClick: () => {},
    },
    {
      title: "Gift App",
      key: DASHBOARD_SECTION_GIFT_APP_ACCESS,
      logo: (
        <GiftAppAccessSvg size={"22px"} margin={"0.2em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_GIFT_APP_ACCESS) ? "#FFFFFF" : "#707070"} />
      ),
      onClick: () => {},
    },
    {
      title: "FAQ",
      key: DASHBOARD_SECTION_FAQ,
      logo: (
        <FaqSvg size={"18px"} margin={"0.25em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_FAQ) ? "#FFFFFF" : "#707070"} />
      ),
      onClick: () => {},
    },
    {
      title: "Log out",
      key: DASHBOARD_SECTION_LOGOUT,
      logo: (
        <LogOutSvg size={"15px"} margin={"0.3em 0px 0px 0px"} color={(selectedMenuSection === DASHBOARD_SECTION_LOGOUT) ? "#FFFFFF" : "#707070"} />
      ),
      onClick: () => onLogoutUser(),
    }
  ]
  return (
    <div style={{position: "fixed", top: 0, left: 0, right: 0, height: 120, backgroundColor: "#000000", zIndex: 1000}}>
      <div style={{padding: "15px 0px"}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%", padding: "0px 5% 10px 5%"}}>
          <NeuroFitNamedLogoSvg
            logoSize={"min(3.5vw, 15px)"}
            color={"#FFFFFF"}
            onClick={() => window.location.reload(true/*hardReload*/)}
          />
          <div style={{maxWidth: "60vw", padding: "14px 0px 6px 10px", lineHeight: "min(3.5vw, 15px)", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", display: "flex", alignItems: "center", justifyContent: "left"}}>
            <div style={{color: "#EDEEF2", textTransform: "uppercase", marginInlineStart: "0.2em", fontFamily: "Futura", lineHeight: "min(3.5vw, 15px)", fontSize: "min(3.5vw, 15px)"}}>
              {`${trainerFirstName} ${trainerLastName}`}
            </div>
          </div>
        </div>
        <div style={{width: "100%", overflowX: "scroll", overflowY: "hidden", WebkitOverflowScrolling: "touch"}} className="disable-scrollbars">
          <div style={{display: "flex",  justifyContent: "space-between", width: "90%", padding: "0px 5%"}}>
            {dashboardMenuItems.map((item, idx) => {
              if (item.is_spacing) {
                return (
                  <div key={idx} style={{width: "4vh"}} />
                )
              } else {
                return (
                  <DashboardTopMenuButton
                    key={idx}
                    isSelected={selectedMenuSection === item.key}
                    title={item.title}
                    hideTitle={!isDesktop}
                    logo={item.logo}
                    onClick={() => {
                      item.onClick()
                      onUpdateSelectedMenuSection(item.key)
                    }}
                  />
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}


export default DashboardSideMenu;