import React, { Suspense } from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  sanitizeEmailAddress,
  ProductionCertificationUrl,
  formatReadableUnixTimestampMins,
  getAbbreviatedDurationSinceTimestamp,
} from '../../../utils'

import {
  ActivityArrowSvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
} from '../../../constants/dashboardSvgs'

import AppStripeCheckoutForm from '../AppStripeCheckoutForm'
import CompletionCheckIcon from '../CompletionCheckIcon'
import EditableEmailTextInput from '../EditableEmailTextInput'

const Select = React.lazy(() => import('../SelectDropdown'));

const GiftAppTabViews = [
  {
    key: "create",
    value: "Create New"
  },
  {
    key: "history",
    value: "History"
  }
]

class GiftAppAccessTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        codeIsCopied: false,
        clientDetails: {
          client_first_name: "",
          client_email_address: "",
          months_app_access: 0
        },
        selectedSubView: "create",
        dropDownIsOpen: false
     }
  }

  componentWillUnmount() {
    this.props.onResetAppPaymentIntent()
  }

  render() {

    const {
      appInviteCode,
      timeOfDay,
      firstName,
      emailAddress,
      fetchGiftAppHistoryRequestResult,
      fetchGiftAppDetailsRequestResult,
      updateGiftAppDetailsRequestResult,
      onFetchGiftAppHistory,
      onFetchGiftAppDetails,
      onUpdateGiftAppDetails,
      onResetUpdateGiftAppDetailsRequest,
      onCreateAppPaymentIntent,
      appPaymentIntentRequestResult,
      hasTabAccess,
      isDesktop,
      innerWidth
    } = this.props

    const {
      codeIsCopied,
      clientDetails,
      selectedSubView,
      dropDownIsOpen
    } = this.state

    const {
      client_first_name,
      client_email_address,
      months_app_access
    } = clientDetails

    const disableCopyButton = codeIsCopied
    const formFieldsDisabled = (appPaymentIntentRequestResult.state === REQUEST_FETCHING) || (appPaymentIntentRequestResult.state === REQUEST_SUCCESS)
    const createPaymentIntentButtonDisabled = formFieldsDisabled || (!client_first_name || !client_email_address || months_app_access === 0)
    const createPaymentIntentButtonText = (appPaymentIntentRequestResult.state === REQUEST_FETCHING) ? ("LOADING CHECKOUT...") : "LOAD CHECKOUT"
    return (
      <div
        style={{marginTop: isDesktop ? 100 : 0, display: "flex", width: "100%", justifyContent: "center"}}
      >
        <div style={{width: "min(85%, 1000px)"}}>
          <div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh"}}>
              <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                {`Gift App Access`}
              </div>
              {selectedSubView === "history" && (
                <div
                  style={{display: "flex", cursor: "pointer", alignItems: "center"}}
                  onClick={() => onFetchGiftAppHistory()}
                >
                  {isDesktop && <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{(fetchGiftAppHistoryRequestResult.state === REQUEST_SUCCESS) && `UPDATED ${formatReadableUnixTimestampMins(fetchGiftAppHistoryRequestResult.result.created_at)}`}</span>}
                  <RefreshSvg
                    size={"min(4vw, 18px)"}
                    color={"#777777"}
                    onClick={() => {}}
                  />
                </div>
              )}             
            </div>
            <div style={{minWidth: "100%", display: "flex", justifyContent: "start", marginBottom: "2vh"}}>
              <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", overflowY: "visible", whiteSpace: "nowrap"}}>
                {GiftAppTabViews.map(view => {
                  const isSelected = (view.key === selectedSubView)
                  return (
                    <div
                      key={view.key}
                      style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                      onClick={() => this.setState({selectedSubView: view.key})}
                    >
                      {view.value}
                    </div>
                  )
                })}
              </div>
            </div>
            {selectedSubView === "create"  && (
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{minWidth: "100%"}}>
                    <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                      {`RECIPIENT DETAILS`}
                    </div>
                    <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light"}}>
                      {"Gift the NEUROFIT App to a client or friend. Their app access will be tied to the email address entered in this form."}
                    </div>
                    <div style={{marginTop: "2vh", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                      <input
                        type="text"
                        value={client_first_name}
                        disabled={formFieldsDisabled}
                        autoCapitalize="true"
                        autoCorrect="false"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({...this.state, clientDetails: {...clientDetails, client_first_name: e.target.value}})
                        }}
                        onPaste={(e) => {
                          this.setState({...this.state, clientDetails: {...clientDetails, client_first_name: e.target.value}})
                        }}
                        placeholder={"Recipient First Name"}
                        className={"application-input"}
                      />
                    </div>
                    <div style={{marginTop: "2vh"}}>
                      <input
                        type="text"
                        value={client_email_address}
                        disabled={formFieldsDisabled}
                        autoCapitalize="none"
                        autoCorrect="false"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({...this.state, clientDetails: {...clientDetails, client_email_address: sanitizeEmailAddress(e.target.value)}})
                        }}
                        onPaste={(e) => {
                          this.setState({...this.state, clientDetails: {...clientDetails, client_email_address: sanitizeEmailAddress(e.target.value)}})
                        }}
                        placeholder={"Recipient Email Address"}
                        className={"application-input"}
                      />
                    </div>
                    <div style={{marginTop: "2vh"}}>
                      <Suspense fallback={
                        <div className={"application-input"} style={{border: "none", opacity: 1.0, padding: 8, borderColor: "transparent", color: "#BABDC6", fontFamily: "Futura"}}>
                          {"Select Months of App Access"}
                        </div>
                      }>
                        <Select
                          style={{border: "none", opacity: 1.0, padding: 8, borderColor: "transparent", boxShadow: "none", color: "#000000", fontFamily: "Futura"}}
                          className={"application-input"}
                          values={[{value: months_app_access, label: "Select Months of App Access"}]}
                          disabled={formFieldsDisabled}
                          options={[
                            {value: 3, label: "3 Months ($35.99 USD)"},
                            {value: 12, label: "12 Months ($124.99 USD)"}
                          ]}
                          onChange={(values) => {
                            this.setState({...this.state, dropDownIsOpen: false, clientDetails: {...clientDetails, months_app_access: values[0].value}})
                          }}
                          labelField={"label"}
                          valueFields={"value"}
                          color={"#000000"}
                          contentRenderer={({state}) => (
                            <div style={{border: "none", padding: 0, borderColor: "transparent", boxShadow: "none", fontSize: "min(5vw, 22px)", textAlign: "start", color: dropDownIsOpen ? "#a3a8bd" : ((months_app_access === 0) ? "#BABDC6" : "#000000"), fontFamily: "Futura"}}>
                              {state.values[0].label}
                            </div>
                          )}
                          dropdownHandleRenderer={() => (
                            ((!(formFieldsDisabled) && (months_app_access === 0)) ? (
                              <svg style={{height: "min(3vw, 14px)", width: "min(3vw, 14px)"}} width="725px" height="725px" viewBox="0 0 725 725" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-40" transform="translate(0.804489, 0.300000)"><rect id="Rectangle" x="0" y="0" width="724" height="724"></rect><g id="noun-chevron-152840" transform="translate(22.195511, 157.700000)" fill={dropDownIsOpen ? "#a3a8bd" : ((months_app_access === 0) ? "#BABDC6" : "#000000")} fillRule="nonzero"><path d="M291.99,388.1595 L19.93,116.0995 C6.645,102.8145 -5.68434189e-14,85.4195 -5.68434189e-14,68.0135 C-5.68434189e-14,50.6115 6.6445,33.2125 19.93,19.9275 C46.477,-6.6425 89.563,-6.6425 116.11,19.9275 L340.07,243.8875 L564.04,19.9275 C590.587,-6.6425 633.673,-6.6425 660.22,19.9275 C686.79,46.4975 686.79,89.5365 660.22,116.1075 L388.16,388.1675 C361.613,414.7295 318.523,414.7295 291.984,388.159688 L291.99,388.1595 Z" id="Path"></path></g></g></g></svg>
                            ) : (
                              <span style={{height: 0, width: 0, padding: 0, margin: 0}}></span>
                            ))
                          )}
                        />
                      </Suspense>
                    </div>
                    <button
                      style={{display: "block", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(5.65vw, 25px)", marginTop: "2vh", color: createPaymentIntentButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "15px 18px", border: "none"}}
                      disabled={createPaymentIntentButtonDisabled}
                      onClick={() => {
                        onCreateAppPaymentIntent({
                          client_first_name,
                          client_email_address,
                          months_app_access
                        })
                      }}
                    >
                      {createPaymentIntentButtonText}
                    </button>
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        timeout={500}
                        classNames={"hide-fade-in"}
                        unmountOnExit
                        addEndListener={(node, done) => {
                          node.addEventListener("transitionend", done, false);
                        }}
                        key={(appPaymentIntentRequestResult.state !== REQUEST_SUCCESS && appPaymentIntentRequestResult !== REQUEST_ERROR)}
                      >
                        <div>
                          {(appPaymentIntentRequestResult.state === REQUEST_FETCHING) && (
                            <div>
                              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                                {`LOADING CHECKOUT FORM...`}
                              </div>
                            </div>
                          )}
                          {(appPaymentIntentRequestResult.state === REQUEST_ERROR) && (
                            <div>
                              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                                {`There was an error loading the checkout form - please double-check the recipient's first name and email address, then retry.`}
                              </div>
                            </div>
                          )}
                          {(appPaymentIntentRequestResult.state === REQUEST_SUCCESS) && (
                            <div>
                              <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                                {`PAYMENT DETAILS`}
                              </div>
                              <AppStripeCheckoutForm
                                clientSecret={appPaymentIntentRequestResult.result.client_secret}
                                priceUsdCents={appPaymentIntentRequestResult.result.price_usd_cents}
                                productName={`NEUROFIT App: ${months_app_access} Months`}
                                pixelProductId={`neurofit_app_pre_purchase_${months_app_access}_months`}
                                trainerEmailAddress={emailAddress}
                              />
                            </div>
                          )}
                        </div>
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                </div>
              </div>
            )}
            {selectedSubView === "history" && (
              <GiftedAppHistoryView
                onFetchGiftAppHistory={() => onFetchGiftAppHistory()}
                onFetchGiftAppDetails={() => onFetchGiftAppDetails()}
                onUpdateGiftAppDetails={(requestBody) => onUpdateGiftAppDetails(requestBody)}
                onResetUpdateGiftAppDetailsRequest={() => onResetUpdateGiftAppDetailsRequest()}
                fetchGiftAppHistoryRequestResult={fetchGiftAppHistoryRequestResult}
                fetchGiftAppDetailsRequestResult={fetchGiftAppDetailsRequestResult}
                updateGiftAppDetailsRequestResult={updateGiftAppDetailsRequestResult}
                onOpenAppGiftCheckout={() => this.setState({selectedSubView: "create"})}
                isDesktop={isDesktop}
                innerWidth={innerWidth}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

class GiftedAppHistoryView extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        selectedPaymentIntentId: undefined,
     }
  }

  
  componentDidMount() {
    this.props.onFetchGiftAppHistory()
  }

  render() {

    const {
      appInviteCode,
      timeOfDay,
      firstName,
      emailAddress,
      fetchGiftAppHistoryRequestResult,
      updateGiftAppDetailsRequestResult,
      onFetchGiftAppHistory,
      onResetUpdateGiftAppDetailsRequest,
      onUpdateGiftAppDetails,
      onOpenAppGiftCheckout,
      isDesktop,
      innerWidth
    } = this.props

    const {
      selectedPaymentIntentId,
    } = this.state


    const appGiftPreviews = fetchGiftAppHistoryRequestResult.state === REQUEST_SUCCESS ? fetchGiftAppHistoryRequestResult.result.app_gifts : []

    return (
      <div>
        {(fetchGiftAppHistoryRequestResult.state === REQUEST_FETCHING) && (
          <div>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`LOADING APP GIFT HISTORY...`}
            </div>
          </div>
        )}
        {(fetchGiftAppHistoryRequestResult.state === REQUEST_ERROR) && (
          <div>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`ERROR LOADING HISTORY`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
              {`There was an error loading your App Gifting History - please retry.`}
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  onFetchGiftAppHistory()
                }}
              >
                <span>
                  {"RETRY"}
                </span>
              </button>
            </div>
          </div>
        )}
        {(fetchGiftAppHistoryRequestResult.state === REQUEST_SUCCESS) && (
          <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
            <div style={{backgroundColor: "#fbfbfc", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(3vw, 14px)", padding: "min(3.5vw, 16px)"}}>
              {isDesktop && (
                <div style={{width: "25%", display: "inline-block"}}>
                  {"NAME"}
                </div>
              )}
              <div style={{width: isDesktop ? "45%" : "100%", display: "inline-block", textAlign: "start"}}>
                {isDesktop ? "EMAIL ADDRESS" : "RECEIVING EMAIL ADDRESS"}
              </div>
              {isDesktop && (
                <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
                  {"# MONTHS"}
                </div>
              )}
              {isDesktop && (
                <div style={{width: "15%", display: "inline-block", textAlign: "end"}}>
                  {"REDEEMED"}
                </div>
              )}
            </div>
            {appGiftPreviews.map(preview => (
              <div key={preview.id} style={{width: "100%", borderTop: "1px #dddddd solid", fontSize: "min(4vw, 18px)", fontFamily: "Futura"}}>
                <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
                  {isDesktop && (
                    <div style={{width: "25%", display: "inline-block"}}>
                      <span style={{fontSize: "min(4.5vw, 20px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {preview.client_first_name}
                      </span>
                      <div style={{color: "#000000", fontSize:" min(2.5vw, 12px)", fontFamily: "Futura Medium", textTransform: "uppercase", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center"}}>
                        <span>{`Gifted ${getAbbreviatedDurationSinceTimestamp(preview.created_at_unix)} Ago`}</span>
                      </div>
                    </div>
                  )}
                  <div style={{width: isDesktop ? "45%" : "100%", display: "inline-block"}}>
                    <EditableEmailTextInput
                      existingEmailAddress={preview.client_email_address}
                      disableInput={preview.is_redeemed}
                      hideSubmitButton={false}
                      emailAddressRequestState={(selectedPaymentIntentId === preview.stripe_payment_intent_id) ? updateGiftAppDetailsRequestResult.state : REQUEST_UNSTARTED}
                      onSubmitEmailAddress={(emailAddress) => {
                        onUpdateGiftAppDetails({updated_client_email_address: emailAddress, updated_client_first_name: preview.client_first_name, stripe_payment_intent_id: preview.stripe_payment_intent_id})
                      }}
                      onFocus={() => {
                        this.setState({selectedPaymentIntentId: preview.stripe_payment_intent_id})
                        onResetUpdateGiftAppDetailsRequest()
                      }}
                      onUpdateEmailAddress={() => onResetUpdateGiftAppDetailsRequest()}
                      submitButtonText={"SAVE"}
                      inputPlaceholderText={"Email Address"}
                    />
                  </div>
                  {isDesktop && (
                    <div style={{width: "15%", display: "inline-block"}}>
                      <div style={{fontSize: "min(4.5vw, 20px)", cursor: "pointer", textAlign: "end", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {preview.months_app_access}
                      </div>
                    </div>
                  )}
                  {isDesktop && (
                    <div style={{width: "15%", display: "inline-block", height: 22, textAlign: "end"}}>
                      <CompletionCheckIcon
                        isComplete={preview.is_redeemed}
                        marginTop={"0px"}
                        checkboxBaseClassName={"checkbox-plan-button"}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div style={{width: "100%", backgroundColor: "#fbfbfc", color: (appGiftPreviews.length > 0) ? "#999999" : "#777777", borderTop: "1px #dddddd solid", fontSize: "min(3.75vw, 16px)", fontFamily: "Futura Book"}}>
              <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
                {(appGiftPreviews.length > 0) ?
                  "Pro-Tip: Click an email address to edit as needed." : (
                  <span>
                    <span style={{fontFamily: "Futura", textDecoration: "underline", cursor: "pointer"}} onClick={() => onOpenAppGiftCheckout()}>
                      {"Gift The App"}
                    </span>
                    {" to a client + find the details here."}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default GiftAppAccessTab