import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range, mean, map, sum } from 'lodash-es';
import LoadingLogo from '../common/LoadingLogo'
import TopErrorMessage from '../common/TopErrorMessage'
import {history} from '../../history';

import DashboardSideMenu from '../common/dashboard/DashboardSideMenu'
import DashboardTopNavigationBar from '../common/dashboard/DashboardTopNavigationBar'
import DashboardMobileTopMenu from '../common/dashboard/DashboardMobileTopMenu'
import AffiliateProfileTab from '../common/dashboard/AffiliateProfileTab'
import ClientsTab from '../common/dashboard/ClientsTab'
import SessionsTab from '../common/dashboard/SessionsTab'
import QuizLeadsTab from '../common/dashboard/QuizLeadsTab'
import ProfileLinkTab from '../common/dashboard/ProfileLinkTab'
import GiftAppAccessTab from '../common/dashboard/GiftAppAccessTab'
// import ContentCopilotTab from '../common/dashboard/ContentCopilotTab'
// import ClearBusinessTab from '../common/dashboard/ClearBusinessTab'
// import CoachCopilotTab from '../common/dashboard/CoachCopilotTab'
// import CoachTrainingTab from '../common/dashboard/CoachTrainingTab'
import FaqTab from '../common/dashboard/FaqTab'
import TrainerAcceptClientBookingExplanationModal from '../modals/TrainerAcceptClientBookingExplanationModal'
import TrainerDiscountedSessionBundlingExplanationModal from '../modals/TrainerDiscountedSessionBundlingExplanationModal'
import TrainerAISalesCopilotExplanationModal from '../modals/TrainerAISalesCopilotExplanationModal'
import TrainerAIBrandingCopilotExplanationModal from '../modals/TrainerAIBrandingCopilotExplanationModal'

import {
  REQ_ACTION__FLASH_TOP_ERROR_MESSAGE,

  REQ_DASHBOARD_FETCH_APP_CONFIG,

  REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE,
  REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL,
  REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL,
  REQ_DASHBOARD_UPDATE_AFFILIATE_CODE,
  REQ_DASHBOARD_FETCH_CLIENT_LIST,
  REQ_DASHBOARD_FETCH_CLIENT_DETAILS,
  REQ_DASHBOARD_REMOVE_CLIENT,
  REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL,
  REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES,
  REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE,
  REQ_DASHBOARD_SEND_CLIENT_PUSH_NOTIFICATION,
  REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT,
  REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY,
  REQ_DASHBOARD_FETCH_GIFT_APP_DETAILS,
  REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS,
  REQ_DASHBOARD_FETCH_SESSION_LIST,
  REQ_DASHBOARD_FETCH_SESSION_DETAILS,
  REQ_DASHBOARD_UPDATE_SESSION_DETAILS,
  REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN,
  REQ_DASHBOARD_FETCH_LEAD_LIST,
  REQ_DASHBOARD_FETCH_LEAD_DETAILS,
  REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT,
  REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY,
  REQ_DASHBOARD_REMOVE_CLIENT_LEAD,
  REQ_DASHBOARD_LOGOUT_USER,

} from '../../constants/requestTypes'

import {
  FORM_FIELD_AFFILIATE_CODE,
  FORM_FIELD_USER_PROFILE_IMAGE_FILE,
  FORM_FIELD_SHOW_TOP_ERROR_MESSAGE,
  FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE,
  FORM_FIELD_TOP_ERROR_MESSAGE_VALUE,
  FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE,
} from '../../constants/formFields'

import {
  DASHBOARD_SECTION_CLIENTS,
  DASHBOARD_SECTION_BOOKED_SESSIONS,
  DASHBOARD_SECTION_QUIZ_LEADS,
  DASHBOARD_SECTION_BALANCE_TOOL,
  DASHBOARD_SECTION_EDIT_PROFILE,
  DASHBOARD_SECTION_CONTENT_COPILOT,
  DASHBOARD_SECTION_CLEAR_BUSINESS,
  DASHBOARD_SECTION_COACH_COPILOT,
  DASHBOARD_SECTION_COACH_TRAINING,
  DASHBOARD_SECTION_AFFILIATE,
  DASHBOARD_SECTION_GIFT_APP_ACCESS,
  DASHBOARD_SECTION_SETTINGS,
  DASHBOARD_SECTION_LOGOUT,
  DASHBOARD_SECTION_FAQ,
  DASHBOARD_SECTION_ADMIN_SEO,
  DASHBOARD_SECTION_FEATURE_REQUESTS,
  DASHBOARD_SECTION_SUPPORT,
  DASHBOARD_SECTION_LEGAL_AND_DATA,
} from '../../constants/dashboardSections'

import {
  DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID,
  DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID,
  DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID,
  DASHBOARD_PAGE_STATE_TOP_HABIT,
  DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT,
  DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT,
} from '../../constants/pageState'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../../constants/requestStates';

import {
  getTimeOfDay,
  isTodayUnix,
  getDaysSinceTimestamp,
  getLocalDaysSinceTimestamp,
  getLocalWeeksSinceTimestamp,
  readableDate,
  MinDesktopWidthPixels,
  delay,
} from '../../utils'

const containerStyle = {textAlign: "start", minHeight: "100%", left: 0, right: 0, overflowX: "hidden", overflowY: "scroll", backgroundColor: "#EDEEF2"}


class LoggedInPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        sideBarClickId: 0,
        modals: {
          showSessionBookingsExplanationModal: false,
          showDiscountedSessionBundlingExplanationModal: false,
          showAISalesCopilotExplanationModal: false,
          showAIBrandingCopilotExplanationModal: false
        }
     }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      innerWidth,
      loggedInUserData,
      onUpdateFormField,
      onUpdatePageState,
      onTriggerRequest,
      onUpdateRequestState,
      onResetRequestState,
      onResetHrvCaptureState,

      formFields,
      pageState,
      requestResults,
      currentTab,
      selectedClientUserId,
      selectedClientSubview,

      selectedSessionId,
      selectedSessionSubview,

      selectedLeadId,
      selectedLeadSubview

    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, sideBarClickId, modals} = this.state

    const {
      showSessionBookingsExplanationModal,
      showDiscountedSessionBundlingExplanationModal,
      showAISalesCopilotExplanationModal,
      showAIBrandingCopilotExplanationModal
    } = modals

    const showTopErrorMessage = formFields[FORM_FIELD_SHOW_TOP_ERROR_MESSAGE]
    const showTopSuccessMessage = formFields[FORM_FIELD_SHOW_BOTTOM_SUCCESS_MESSAGE]
    const topErrorMessage = formFields[FORM_FIELD_TOP_ERROR_MESSAGE_VALUE]
    const topSuccessMessage = formFields[FORM_FIELD_BOTTOM_SUCCESS_MESSAGE_VALUE]

    const isDesktop = innerWidth >= MinDesktopWidthPixels

    const timeOfDay = getTimeOfDay()

    return (
      <div>
        {isDesktop && (
          <DashboardSideMenu
            isDesktop={isDesktop}
            isCertifiedTrainer={loggedInUserData.is_certified_trainer}
            isBookingFeatureSetEnabled={loggedInUserData.is_certified_trainer}
            isCertifiedMasterCoach={loggedInUserData.is_certified_master_coach}
            isSeoAdmin={loggedInUserData.is_seo_admin_user}
            onUpdateSelectedMenuSection={newLocation => {
              history.push("/app/" + newLocation)
              this.setState({...this.state, sideBarClickId: sideBarClickId + 1})
            }}
            selectedMenuSection={currentTab}
            trainerFirstName={loggedInUserData.first_name}
            trainerLastName={loggedInUserData.last_name}
            trainerEmailAddress={loggedInUserData.email_address}
            logoutRequestState={requestResults[REQ_DASHBOARD_LOGOUT_USER].state}
            onLogoutUser={() => onTriggerRequest(REQ_DASHBOARD_LOGOUT_USER)}
          />
        )}
        {!isDesktop && (
          <DashboardMobileTopMenu
            onUpdateSelectedMenuSection={newLocation => {
              history.push("/app/" + newLocation)
              this.setState({...this.state, sideBarClickId: sideBarClickId + 1})
            }}
            selectedMenuSection={currentTab}
            trainerFirstName={loggedInUserData.first_name}
            trainerLastName={loggedInUserData.last_name}
            isCertifiedTrainer={loggedInUserData.is_certified_trainer}
            isBookingFeatureSetEnabled={loggedInUserData.is_certified_trainer}
            trainerEmailAddress={loggedInUserData.email_address}
            logoutRequestState={requestResults[REQ_DASHBOARD_LOGOUT_USER].state}
            onLogoutUser={() => onTriggerRequest(REQ_DASHBOARD_LOGOUT_USER)}
          />
        )}
        {isDesktop && (
          <DashboardTopNavigationBar
            isDesktop={isDesktop}
            selectedMenuSection={currentTab}
          />
        )}
        <div style={{position: "fixed", left: isDesktop ? 240 : 0, top: 0, marginTop: isDesktop ? 0 : 120, right: 0, height: innerHeight, overflowX: "hidden", overflowY: "scroll"}}>
            <div 
              id={"logged-in-main"}
              style={containerStyle}
            >
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={100}
                  classNames={"hide-fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={`${currentTab}-${sideBarClickId}`}
                >
                  <div style={{marginBottom: isDesktop ? 20 : 140}}>
                  {currentTab === DASHBOARD_SECTION_CLIENTS && (
                    <ClientsTab
                      isDesktop={isDesktop}
                      timeOfDay={timeOfDay}
                      firstName={loggedInUserData.first_name}
                      hasTabAccess={loggedInUserData.is_certified_trainer || loggedInUserData.is_certified_master_coach || loggedInUserData.is_coach_os_member}
                      appInviteCode={loggedInUserData.app_invite_code}
                      clientListRequestResult={requestResults[REQ_DASHBOARD_FETCH_CLIENT_LIST]}
                      selectedClientDetailsRequestResult={requestResults[REQ_DASHBOARD_FETCH_CLIENT_DETAILS]}
                      selectedClientId={selectedClientUserId}
                      selectedClientSubview={selectedClientSubview}
                      onLoadClientList={() => onTriggerRequest(REQ_DASHBOARD_FETCH_CLIENT_LIST)}
                      onSelectClient={clientId => {
                        onUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_CLIENT_ID, clientId)
                        if (clientId !== undefined) {
                          history.push(`/app/${DASHBOARD_SECTION_CLIENTS}/${clientId}/insights`)
                        } else {
                          history.push(`/app/${DASHBOARD_SECTION_CLIENTS}`)
                        }
                      }}
                      onNavigateToClientSubview={subViewKey => {
                        history.push(`/app/${DASHBOARD_SECTION_CLIENTS}/${selectedClientUserId}/${subViewKey}`)
                      }}
                      onLoadClientDetails={() => onTriggerRequest(REQ_DASHBOARD_FETCH_CLIENT_DETAILS)}
                      onResetClientList={() => onResetRequestState(REQ_DASHBOARD_FETCH_CLIENT_LIST)}
                      onResetClientDetails={() => onResetRequestState(REQ_DASHBOARD_FETCH_CLIENT_DETAILS)}
                      onRemoveClient={() => onTriggerRequest(REQ_DASHBOARD_REMOVE_CLIENT)}
                      onResetTestimonialRequest={() => onResetRequestState(REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL)}
                      onRequestTestimonial={() => onTriggerRequest(REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL)}
                      removeClientRequestResult={requestResults[REQ_DASHBOARD_REMOVE_CLIENT]}
                      requestTestimonialRequestResult={requestResults[REQ_DASHBOARD_REQUEST_CLIENT_TESTIMONIAL]}
                      topHabit={pageState[DASHBOARD_PAGE_STATE_TOP_HABIT]}
                      topHabitText={pageState[DASHBOARD_PAGE_STATE_TOP_HABIT_TEXT]}
                      topLifeAspect={pageState[DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT]}
                      topLifeAspectText={pageState[DASHBOARD_PAGE_STATE_TOP_LIFE_ASPECT_TEXT]}
                      innerWidth={innerWidth}
                    />
                  )}
                  {/*{(currentTab === DASHBOARD_SECTION_BOOKED_SESSIONS) && (
                    <SessionsTab
                      isDesktop={isDesktop}
                      timeOfDay={timeOfDay}
                      firstName={loggedInUserData.first_name}
                      isBookingFeatureSetEnabled={loggedInUserData.is_certified_trainer}
                      sessionListRequestResult={requestResults[REQ_DASHBOARD_FETCH_SESSION_LIST]}
                      selectedSessionDetailsRequestResult={requestResults[REQ_DASHBOARD_FETCH_SESSION_DETAILS]}
                      selectedSessionId={selectedSessionId}
                      selectedSessionSubview={selectedSessionSubview}
                      onLoadSessionList={() => onTriggerRequest(REQ_DASHBOARD_FETCH_SESSION_LIST)}
                      onSelectSession={sessionId => {
                        onUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_SESSION_ID, sessionId)
                        if (sessionId !== undefined) {
                          history.push(`/app/sessions/${sessionId}/details`)
                        } else {
                          history.push(`/app/sessions`)
                        }
                      }}
                      onNavigateToSessionSubview={subViewKey => {
                        history.push(`/app/sessions/${selectedSessionId}/${subViewKey}`)
                      }}
                      onNavigateToProfileLinkTab={() => {
                        history.push(`/app/profile`)
                      }}
                      onLoadSessionDetails={() => onTriggerRequest(REQ_DASHBOARD_FETCH_SESSION_DETAILS)}
                      onResetSessionList={() => onResetRequestState(REQ_DASHBOARD_FETCH_SESSION_LIST)}
                      onResetSessionDetails={() => onResetRequestState(REQ_DASHBOARD_FETCH_SESSION_DETAILS)}
                      onUpdateSession={requestBody => {
                        onUpdateRequestState(REQ_DASHBOARD_UPDATE_SESSION_DETAILS, requestBody)
                        onTriggerRequest(REQ_DASHBOARD_UPDATE_SESSION_DETAILS)
                      }}
                      onResetUpdateSessionRequest={() => onResetRequestState(REQ_DASHBOARD_UPDATE_SESSION_DETAILS)}
                      updateSessionRequestResult={requestResults[REQ_DASHBOARD_UPDATE_SESSION_DETAILS]}
                      refundClientSessionPlanRequestResult={requestResults[REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN]}
                      onRefundClientSessionPlan={() => onTriggerRequest(REQ_DASHBOARD_REFUND_CLIENT_SESSION_PLAN)}
                      innerWidth={innerWidth}
                    />
                  )}*/}
{/*                  {currentTab === DASHBOARD_SECTION_COACH_COPILOT && (
                    <CoachCopilotTab
                      isDesktop={isDesktop}
                      innerWidth={innerWidth}
                      timeOfDay={timeOfDay}
                      firstName={loggedInUserData.first_name}
                      isBookingFeatureSetEnabled={loggedInUserData.is_certified_trainer}
                    />
                  )}*/}
{/*                  {currentTab === DASHBOARD_SECTION_COACH_TRAINING && (
                    <CoachTrainingTab
                      isDesktop={isDesktop}
                      timeOfDay={timeOfDay}
                      firstName={loggedInUserData.first_name}
                      isBookingFeatureSetEnabled={loggedInUserData.is_certified_trainer}
                    />
                  )}*/}
                  {(currentTab === DASHBOARD_SECTION_QUIZ_LEADS) && (
                    <QuizLeadsTab
                      isDesktop={isDesktop}
                      hasTabAccess={loggedInUserData.is_certified_trainer  || loggedInUserData.is_certified_master_coach || loggedInUserData.is_coach_os_member}
                      leadListRequestResult={requestResults[REQ_DASHBOARD_FETCH_LEAD_LIST]}
                      onLoadLeadList={() => onTriggerRequest(REQ_DASHBOARD_FETCH_LEAD_LIST)}
                      onSelectLead={leadId => {
                        onUpdatePageState(DASHBOARD_PAGE_STATE_SELECTED_LEAD_ID, leadId)
                        if (leadId !== undefined) {
                          history.push(`/app/${DASHBOARD_SECTION_QUIZ_LEADS}/${leadId}/details`)
                        } else {
                          history.push(`/app/${DASHBOARD_SECTION_QUIZ_LEADS}`)
                        }
                      }}
                      onResetLeadList={() => onResetRequestState(REQ_DASHBOARD_FETCH_LEAD_LIST)}
                      onResetLeadDetails={() => onResetRequestState(REQ_DASHBOARD_FETCH_LEAD_DETAILS)}
                      onResetGeneratedLeadResponseDraft={() => onResetRequestState(REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT)}
                      selectedLeadId={selectedLeadId}
                      selectedLeadSubview={selectedLeadSubview}
                      onLoadLeadDetails={() => onTriggerRequest(REQ_DASHBOARD_FETCH_LEAD_DETAILS)}
                      onNavigateToLeadSubview={subViewKey => {
                        history.push(`/app/${DASHBOARD_SECTION_QUIZ_LEADS}/${selectedLeadId}/${subViewKey}`)
                      }}
                      onNavigateToProfileLinkTab={() => {
                        history.push(`/app/profile`)
                      }}
                      selectedLeadDetailsRequestResult={requestResults[REQ_DASHBOARD_FETCH_LEAD_DETAILS]}
                      onSendClientLeadReply={requestBody => {
                        onUpdateRequestState(REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY, requestBody)
                        onTriggerRequest(REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY)
                      }}
                      onGenerateClientDiscoveryQuizResponseDraft={() => {
                        onTriggerRequest(REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT)
                      }}
                      createClientLeadResponseDraftRequestResult={requestResults[REQ_DASHBOARD_GENERATE_CLIENT_LEAD_RESPONSE_DRAFT]}
                      onResetSendClientLeadReplyRequest={() => onResetRequestState(REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY)}
                      sendClientLeadReplyRequestResult={requestResults[REQ_DASHBOARD_SEND_CLIENT_LEAD_REPLY]}
                      removeClientLeadRequestResult={requestResults[REQ_DASHBOARD_REMOVE_CLIENT_LEAD]}
                      onRemoveClientLead={() => onTriggerRequest(REQ_DASHBOARD_REMOVE_CLIENT_LEAD)}
                      innerWidth={innerWidth}
                    />
                  )}
                  {currentTab === DASHBOARD_SECTION_EDIT_PROFILE && (
                    <ProfileLinkTab
                      isDesktop={isDesktop}
                      existingUsername={loggedInUserData.username}
                      existingFirstName={loggedInUserData.first_name}
                      existingLastName={loggedInUserData.last_name}
                      existingEmailAddress={loggedInUserData.email_address}
                      hasCompletedProfileOnboarding={loggedInUserData.completed_profile_onboarding}
                      hasCompletedStripeOnboarding={loggedInUserData.completed_stripe_connect_onboarding}
                      existingOccupation={loggedInUserData.occupation}
                      existingBackgroundExperienceTitle={loggedInUserData.background_experience_title}
                      existingBackgroundExperienceDescription={loggedInUserData.background_experience_description}
                      existingCoachingNicheTitle={loggedInUserData.coaching_niche_title}
                      existingCoachingNicheDescription={loggedInUserData.coaching_niche_description}
                      existingCoachLocationCountryCode={loggedInUserData.profile_location_country_code}
                      existingPersonalUrl={loggedInUserData.workplace_url}
                      existingHourlyRateUsdCents={loggedInUserData.hourly_rate_usd_cents}
                      existingIsClientSessionBookingEnabled={loggedInUserData.is_client_session_booking_enabled}
                      existingIsDiscountedSessionBundlingEnabled={loggedInUserData.is_discounted_session_bundling_enabled}
                      existingYearsProfessionalExperience={loggedInUserData.years_professional_experience}
                      existingProfileImageVersion={loggedInUserData.profile_image_version}
                      hasTabAccess={loggedInUserData.is_certified_trainer  || loggedInUserData.is_certified_master_coach || loggedInUserData.is_coach_os_member}
                      trainerUuid={loggedInUserData.uuid}
                      innerHeight={innerHeight}
                      safeAreaInsets={safeAreaInsets}
                      onSaveProfileLinkUpdates={requestBody => {
                        onUpdateRequestState(REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES, requestBody)
                        onTriggerRequest(REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES)
                      }}
                      onSaveUpdatedProfileImage={file => {
                        onUpdateFormField(FORM_FIELD_USER_PROFILE_IMAGE_FILE, file)
                        onTriggerRequest(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE)
                      }}
                      onFlashTopErrorMessage={async message => {
                        onUpdateFormField(FORM_FIELD_TOP_ERROR_MESSAGE_VALUE, message)
                        onUpdateFormField(FORM_FIELD_SHOW_TOP_ERROR_MESSAGE, true)
                        await delay(3000)
                        onUpdateFormField(FORM_FIELD_SHOW_TOP_ERROR_MESSAGE, false)
                      }}
                      onOpenAffiliateTab={() => {
                        history.push(`/app/affiliate`)
                      }}
                      saveUpdatedProfileImageRequestResult={requestResults[REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE]}
                      saveProfileLinkUpdatesRequestResult={requestResults[REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES]}
                      onResetSaveProfileLinkUpdates={() => onResetRequestState(REQ_DASHBOARD_SAVE_PROFILE_LINK_UPDATES)}
                      onResetSaveUpdatedProfileImage={() => onResetRequestState(REQ_DASHBOARD_UPDATE_PROFILE_IMAGE_SEQUENCE)}
                      onShowAcceptBookingsExplanationModal={() => this.setState({modals: {showSessionBookingsExplanationModal: true}})}
                      onShowDiscountedSessionBundlingExplanationModal={() => this.setState({modals: {showDiscountedSessionBundlingExplanationModal: true}})}
                    />
                  )}
                  {currentTab === DASHBOARD_SECTION_AFFILIATE && (
                    <AffiliateProfileTab
                      isDesktop={isDesktop}
                      timeOfDay={timeOfDay}
                      firstName={loggedInUserData.first_name}
                      existingAffiliateCode={loggedInUserData.affiliate_code}
                      currentInputAffiliateCode={formFields[FORM_FIELD_AFFILIATE_CODE]}
                      affiliateProfileRequestResult={requestResults[REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE]}
                      updateAffiliateCodeRequestResult={requestResults[REQ_DASHBOARD_UPDATE_AFFILIATE_CODE]}
                      startPayoutsOnboardingRequestState={requestResults[REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL].state}
                      loadPayoutsDashboardRequestState={requestResults[REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL].state}
                      onResetAffiliateCodeRequest={() => onResetRequestState(REQ_DASHBOARD_UPDATE_AFFILIATE_CODE)}
                      onUpdateAffiliateCode={affilateCode => onUpdateFormField(FORM_FIELD_AFFILIATE_CODE, affilateCode)}
                      onSaveUpdatedAffiliateCode={(affiliate_code) => {
                        onTriggerRequest(REQ_DASHBOARD_UPDATE_AFFILIATE_CODE)
                      }}
                      onLoadAffiliateProfile={() => onTriggerRequest(REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE)}
                      onStartPayoutsOnboarding={() => onTriggerRequest(REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL)}
                      onLoadPayoutsDashboard={() => onTriggerRequest(REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL)}
                    />
                  )}
                  {currentTab === DASHBOARD_SECTION_GIFT_APP_ACCESS && (
                    <GiftAppAccessTab
                      isDesktop={isDesktop}
                      emailAddress={loggedInUserData.email_address}
                      hasTabAccess={loggedInUserData.is_certified_trainer}
                      onCreateAppPaymentIntent={requestBody => {
                        onUpdateRequestState(REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT, requestBody)
                        onTriggerRequest(REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT)
                      }}
                      fetchGiftAppHistoryRequestResult={requestResults[REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY]}
                      fetchGiftAppDetailsRequestResult={requestResults[REQ_DASHBOARD_FETCH_GIFT_APP_DETAILS]}
                      updateGiftAppDetailsRequestResult={requestResults[REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS]}
                      onFetchGiftAppHistory={() => onTriggerRequest(REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY)}
                      onFetchGiftAppDetails={() => onTriggerRequest(REQ_DASHBOARD_FETCH_GIFT_APP_DETAILS)}
                      onUpdateGiftAppDetails={(requestBody) => {
                        onUpdateRequestState(REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS, requestBody)
                        onTriggerRequest(REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS)
                      }}
                      onResetUpdateGiftAppDetailsRequest={() => onResetRequestState(REQ_DASHBOARD_UPDATE_GIFT_APP_DETAILS)}
                      appPaymentIntentRequestResult={requestResults[REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT]}
                      fetchGiftAppHistoryRequestResult={requestResults[REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY]}
                      onResetAppPaymentIntent={() => onResetRequestState(REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT)}
                      onResetFetchGiftAppHistory={() => onResetRequestState(REQ_DASHBOARD_FETCH_GIFT_APP_HISTORY)}
                    />
                  )}
                  {currentTab === DASHBOARD_SECTION_FAQ && (
                    <FaqTab
                      isDesktop={isDesktop}
                      isCertifiedTrainer={loggedInUserData.is_certified_trainer}
                    />
                  )}
                  {currentTab === DASHBOARD_SECTION_ADMIN_SEO && (
                    null
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
        <TopErrorMessage
          show={showTopErrorMessage}
          message={topErrorMessage}
          safeAreaInsets={safeAreaInsets}
        />
        <TrainerAcceptClientBookingExplanationModal
          onClose={() => this.setState({modals: {showSessionBookingsExplanationModal: false}})}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          show={showSessionBookingsExplanationModal}
        />
        <TrainerDiscountedSessionBundlingExplanationModal
          onClose={() => this.setState({modals: {showDiscountedSessionBundlingExplanationModal: false}})}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          show={showDiscountedSessionBundlingExplanationModal}
        />
        <TrainerAISalesCopilotExplanationModal
          onClose={() => this.setState({modals: {showAISalesCopilotExplanationModal: false}})}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          show={showAISalesCopilotExplanationModal}
        />
        <TrainerAIBrandingCopilotExplanationModal
          onClose={() => this.setState({modals: {showAIBrandingCopilotExplanationModal: false}})}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          show={showAIBrandingCopilotExplanationModal}
        />
      </div>
    )
  }
}

export default LoggedInPage;