import React from 'react'

const DashboardSideMenuButton = ({
	title,
	logo,
	hideTitle,
  isSelected,
	onClick
}) => (
  <div
  >
    <button
      className={isSelected ? "dashboard-side-menu-button-selected" : "dashboard-side-menu-button"}
      style={{marginInlineEnd: "6%", padding: "7px 6% 7px 12%", width: "88%", fontFamily: "Futura", fontSize: "min(4vw, 18px)", border: "none", dropShadow: "none", display: "flex", alignItems: "center", justifyContent: "start"}}
      onClick={() => onClick()}
    >
      <div style={{width: 25}}>
        {logo}
      </div>
      {!(hideTitle) && (
        <span style={{fontSize: "min(4vw, 18px)", marginInlineStart: "0.3em", lineHeight: "min(4vw, 18px)"}}>
          {title}
        </span>
      )}
    </button>
  </div>
)

export default DashboardSideMenuButton