import React, {Suspense} from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';


import { history } from '../../history'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  ProductionBaseUrl,
  ProductionWorkshopsUrl,
  ProductionCertificationUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  KajabiCourseUrl,
} from '../../utils'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_WORKSHOP_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_BUTTON,
  GA_LABEL_CLICKED_QUIZ_BUTTON,
} from '../../constants/gaEventLabels'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class LandingPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      showQrCodeModal: false,
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isVideoLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      hasScrolled: false
    }
  }

  componentDidMount() {
    this.setState({show: true})
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice
    } = this.props

    const {show, showAnswer, learnMoreRef, showSettingsModal, showQrCodeModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isVideoLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, hasScrolled} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const phoneAspectRatio = 1841 / 907

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    const currentYear = getCurrentYear()

    return (
      <div style={{height: innerHeight}}>
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
              onClick={() => window.location = "/"}
            />
          </div>
          <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", height: innerHeight, overflowY: "scroll"}}  ref={elem => this.learnMoreRef = elem}>
            <div style={{minHeight: innerHeight, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <div style={{color: "black", width: "100%", paddingTop: "calc(60px + 5vh)", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Welcome To The NEUROFIT Dashboard"}
                  </div>
                  <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    {"Create your app affiliate link, manage payouts, and optimize client results with data-driven insights."}
                  </div>
                  <button
                    style={{display: "block", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: "6vh", color: "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "12px 15px", border: "none"}}
                    onClick={() => {
                      window.location = "/signup"
                    }}
                  >
                    {"CREATE NEW ACCOUNT"}
                  </button>
                  <button
                    style={{display: "block", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: "2vh", color: "#000000", backgroundColor: "#FFFFFF", transition: "color 250ms linear", padding: "12px 15px", border: "2px #000000 solid"}}
                    onClick={() => {
                      window.location = "/login"
                    }}
                  >
                    {"LOG IN"}
                  </button>
{/*                  <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "6vh"}}>
                    <span style={{marginInlineEnd: "0.2em"}}>{"Not a certified trainer yet? Purchase the NeuroFit Certification "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseAppUrl}/certification`} target="_blank" rel="noopener noreferrer nofollow">{"here"}</a>
                    <span style={{marginInlineEnd: "0.2em"}}>{", or head to the "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={KajabiCourseUrl} target="_blank" rel="noopener noreferrer nofollow">{"Course Dashboard"}</a>
                    <span style={{marginInlineEnd: "0.2em"}}>{" to complete the course."}</span>
                  </div>*/}
                </div>
              </div>
              <div style={{padding: 15, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {`© NEUROFIT ${currentYear}`}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPage;