import React from 'react'

const DashboardTopMenuButton = ({
	title,
	logo,
	hideTitle,
  isSelected,
	onClick
}) => (
  <div
  >
    <button
      className={"dashboard-top-menu-button"}
      style={{marginInlineEnd: 10, border: "none", dropShadow: "none", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
      onClick={() => onClick()}
    >
      <span style={{width: 25, height: 25}}>
        {logo}
      </span>
      <span style={{fontSize: "min(4vw, 18px)", whiteSpace: "nowrap", color: isSelected ? "#FFFFFF" : "#707070", transition: "color 200ms linear", textTransform: "uppercase", marginTop: "0.3em", fontFamily: "Futura", fontSize: "min(3vw, 12px)"}}>
        {title}
      </span>
    </button>
  </div>
)

export default DashboardTopMenuButton