import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  getAbbreviatedDurationSinceTimestamp,
  getReadableDurationSinceTimestamp,
  formatReadableUnixTimestampMins,
  formatReadableUnixTimestamp,
  getDaysSinceTimestamp,
  getTimeOfDay,
  readableDate,
  MinLargeDesktopWidthPixels,
  ProductionCertificationUrl,
} from '../../../utils'

import {
  getInsightForCheckInInsight,
  getInsightForSelfCare,
  getInsightForEmbodiment,
  getInsightForAvoidance,
  getInsightForOverallAverageStatePercentage,
  getInsightForLifeAspectAverageStatePercentage,
  getMetaForRecoveryScoreHomePage,
  getMetaForHeartRateVariability,

  getMetaForDysregulationQuizScore,
  getMetaForQuizSymptomResultsData,
  getMetaForQuizHabitResultsData,
  getMetaForQuizSelfCheckInResultsData,
} from '../../../dashboardUtils'

import PendingResultsPanel from '../PendingResultsPanel'
import HabitBarChartInsightSection from '../HabitBarChartInsightSection'
import PieChartInsightSection from '../PieChartInsightSection'
import HrvResultsPanel from '../HrvResultsPanel'
import LineGraph from '../LineGraph'
import InlineClientTestimonial from '../InlineClientTestimonial'
import ConfirmSection from '../ConfirmSection'

import DiscoveryQuizResults from './DiscoveryQuizResults'

import {
  ActivityArrowSvg,
  BackArrowSvg,
  SendArrowSvg,
  CopySvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
  TimerSvg,
} from '../../../constants/dashboardSvgs'

import CompletionCheckIcon from '../CompletionCheckIcon'

import { toPng, toBlob } from 'html-to-image';

import { saveAs } from 'file-saver';

const ClientTestimonialDomId = "client-testimonial"

const defaultState = {
  codeIsCopied: false,
}
const ClientViews = [
  {
    key: "insights",
    value: "Insights"
  },
  {
    key: "discovery_quiz",
    value: "Discovery Quiz"
  },
  {
    key: "testimonial",
    value: "Testimonial"
  },
  {
    key: "settings", 
    value: "Settings"
  }
]
class ClientsTab extends React.Component {
  constructor(props){
     super(props)
     this.state = defaultState
  }

  componentWillMount() {
    const {
      onLoadClientList,
      onLoadClientDetails,
      hasTabAccess,
      selectedClientId
    } = this.props

    if (hasTabAccess) {
      if (selectedClientId !== undefined) {
        onLoadClientDetails()
      } else {
        onLoadClientList()
      }
    }
  }

  componentWillUnmount() {
    const {
      onResetClientList,
      onResetClientDetails,
      onResetTestimonialRequest,
      onSelectClient,
    } = this.props
    onResetClientList()
    onResetClientDetails()
    onResetTestimonialRequest()
  }

  render() {

    const {
      appInviteCode,
      timeOfDay,
      firstName,
      isDesktop,
      hasTabAccess,
      clientListRequestResult,
      onLoadClientList,
      onSelectClient,
      selectedClientId,
      selectedClientSubview,
      onLoadClientDetails,
      onNavigateToClientSubview,
      selectedClientDetailsRequestResult,
      onRemoveClient,
      removeClientRequestResult,
      onRequestTestimonial,
      onResetTestimonialRequest,
      requestTestimonialRequestResult,
      topHabit,
      topHabitText,
      topLifeAspect,
      topLifeAspectText,
      innerWidth
    } = this.props

    const {
      codeIsCopied,
    } = this.state
    const disableCopyButton = codeIsCopied

    const isDesktopWidth = innerWidth > MinLargeDesktopWidthPixels

    return (
      <div
        style={{marginTop: isDesktop ? 100 : 20, display: "flex", width: "100%", justifyContent: "center"}}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={500}
            classNames={"hide-fade-in"}
            unmountOnExit
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            key={`${(clientListRequestResult.state !== REQUEST_SUCCESS && clientListRequestResult !== REQUEST_ERROR)}-${selectedClientId}-${(selectedClientDetailsRequestResult.state !== REQUEST_SUCCESS && selectedClientDetailsRequestResult.state !== REQUEST_ERROR)}`}
          >
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              {!(selectedClientId) ? (
                <div style={{width: "min(85%, 1000px)"}}>
                  {(clientListRequestResult.state === REQUEST_SUCCESS) && (
                    <div>
                      <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh", marginBottom: "2vh"}}>
                        <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura"}}>
                          {`YOUR CLIENTS`}
                        </div>
                        <div
                          style={{display: "flex", cursor: "pointer", alignItems: "center"}}
                          onClick={() => onLoadClientList()}
                        >
                          <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{isDesktop ? `UPDATED ${formatReadableUnixTimestampMins(clientListRequestResult.result.created_at)}` : "TAP TO REFRESH"}</span>
                          <RefreshSvg
                            size={"min(4vw, 18px)"}
                            color={"#777777"}
                            onClick={() => {}}
                          />
                        </div>                   
                      </div>
                      <div>
                        <ClientListTable  
                          isDesktop={isDesktopWidth}
                          clientPreviews={clientListRequestResult.result.client_previews}
                          onSelectClient={clientId => {
                            onSelectClient(clientId)
                            onLoadClientDetails()
                          }}
                        />
                      </div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh", marginBottom: "2vh"}}>
                        {"ADD CLIENT TO DASHBOARD"}
                      </div>
                      <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", maxWidth: 600}}>
                        <div style={{padding: "min(5vw, 22px)"}}>
                          <div style={{fontSize: "min(5vw, 22px)", fontFamily: "Futura Light"}}>
                            <span>{`Have your client open their NEUROFIT App and navigate to: `}</span>
                            <div style={{fontFamily: "Futura", marginTop: "1vh", color: "#000000"}}>{`Settings > Data > Link Trainer Account`}</div>
                            <div style={{marginTop: "2vh"}}>{`Then have them input your unique trainer code (below): `}</div>
                          </div>
                          <div style={{display: "flex", width: "100%", alignItems: "center", marginTop: "2vh", justifyContent: "start"}}>
                            <div style={{padding: "14px 18px", lineHeight: "14px", border: "2px #000000 solid", backgroundColor: "#FFFFFF", fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Medium"}}>
                              <span>{appInviteCode}</span>
                            </div>
                            <button
                              style={{marginInlineStart: "0.5em", display: "flex", minWidth: "15%", justifyContent: "center", fontFamily: "Futura", fontSize: "18px", cursor: disableCopyButton ? "not-allowed" : "pointer", lineHeight: "18px", color: disableCopyButton ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", transition: "color 200ms", backgroundColor: "#000000", padding: "14px 18px", border: "none"}}
                              disabled={disableCopyButton}
                              onClick={() => {
                                this.setState({codeIsCopied: true})
                                navigator.clipboard.writeText(appInviteCode)
                                setTimeout(() => {
                                  this.setState({codeIsCopied: false})
                                }, 1000)
                              }}
                            >
                              {codeIsCopied ? "COPIED!" : "COPY CODE"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(clientListRequestResult.state === REQUEST_FETCHING) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`LOADING YOUR CLIENTS...`}
                      </div>
                    </div>
                  )}
                  {(clientListRequestResult.state === REQUEST_ERROR) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`ERROR LOADING CLIENTS`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                        {`There was an error loading your client list - please retry.`}
                      </div>
                      <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            onLoadClientList()
                          }}
                        >
                          <span>
                            {"RETRY"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  {(clientListRequestResult.state === REQUEST_UNSTARTED) && (
                    <div>
                      <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                        {`UNLOCK CLIENT DASHBOARD`}
                      </div>
                      <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", marginTop: "2vh"}}>
                        {`Become a NEUROFIT-Certified Coach to unlock the Client Dashboard:`}
                      </div>
                      <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                          onClick={() => {
                            window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                          }}
                        >
                          <span>
                            {"JOIN CERTIFICATION"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{width: "min(100%, 1500px)", display: "flex", justifyContent: "center"}}>
                  {(selectedClientDetailsRequestResult.state === REQUEST_SUCCESS) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{minWidth: "85%"}}>
                          <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginTop: "6vh", marginBottom: "2vh"}}>
                            <div style={{fontSize: "min(5vw, 30px)", fontFamily: "Futura", textTransform: "uppercase", display: "flex", alignItems: "center"}}>
                              <div
                                onClick={() => {
                                  onLoadClientList()
                                  onSelectClient(undefined)
                                  onResetTestimonialRequest()
                                }}
                                style={{cursor: "pointer", display: "inline-block", color: "#777777"}}
                              >
                                {`Clients`}
                              </div>
                              <BackArrowSvg size={"min(9vw, 40px)"} color={"#777777"} rotation={180} />
                              <div style={{display: "inline-block", maxWidth: "50vw", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                                {`${selectedClientDetailsRequestResult.result.client_profile.first_name}`}
                              </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                              {isDesktopWidth && (
                                <span style={{color: "#777777", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)", marginInlineEnd: 6}}>{`UPDATED ${formatReadableUnixTimestampMins(selectedClientDetailsRequestResult.result.created_at)}`}</span>
                              )}
                              <RefreshSvg
                                size={"min(4vw, 18px)"}
                                color={"#777777"}
                                onClick={() => onLoadClientDetails()}
                              />
                            </div>                   
                          </div>
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "start"}}>
                        <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", paddingInlineEnd: "7.5%", paddingInlineStart: "7.5%", overflowY: "visible", whiteSpace: "nowrap"}}>
                          {ClientViews.map(view => {
                            const isSelected = (view.key === selectedClientSubview)
                            return (
                              <div
                                key={view.key}
                                style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                                onClick={() => onNavigateToClientSubview(view.key)}
                              >
                                {view.value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <SwitchTransition mode="out-in">
                          <CSSTransition
                            timeout={500}
                            classNames={"hide-fade-in"}
                            unmountOnExit
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            key={`${selectedClientSubview}`}
                          >
                            <div style={{display: "flex", justifyContent: "center"}}>
                              <div style={{maxWidth: "85%"}}>
                                {selectedClientSubview === "insights" && (
                                  <ClientInsightResults
                                    loggedInUserData={selectedClientDetailsRequestResult.result.client_profile}
                                    timeSeriesUserInsightsData={selectedClientDetailsRequestResult.result.client_profile_time_series_user_insights}
                                    todayUserInsightsData={selectedClientDetailsRequestResult.result.client_profile_today_user_insights}
                                    memberHabitStatistics={selectedClientDetailsRequestResult.result.member_habit_statistics}
                                    timeOfDay={timeOfDay}
                                    topHabit={topHabit}
                                    topHabitText={topHabitText}
                                    topLifeAspect={topLifeAspect}
                                    topLifeAspectText={topLifeAspectText}
                                    isDesktopWidth={isDesktopWidth}
                                  />
                                )}
                                {selectedClientSubview === "discovery_quiz" && (
                                  <DiscoveryQuizResults
                                    isCompleted={selectedClientDetailsRequestResult.result.client_profile.completed_discovery_quiz}
                                    firstName={selectedClientDetailsRequestResult.result.client_profile.first_name}
                                    timeOfDay={timeOfDay}
                                    quizResultObject={selectedClientDetailsRequestResult.result.discovery_quiz_results}
                                    isDesktopWidth={isDesktopWidth}
                                  />
                                )}
                                {selectedClientSubview === "testimonial" && (
                                  <RequestClientTestimonialPanel
                                    isDesktop={isDesktopWidth}
                                    requestTestimonialRequestResult={requestTestimonialRequestResult}
                                    clientFirstName={selectedClientDetailsRequestResult.result.client_profile.first_name}
                                    clientCountryCode={selectedClientDetailsRequestResult.result.client_country_code}
                                    connectedAtUnix={selectedClientDetailsRequestResult.result.client_relationship_data.linked_account_at_unix}
                                    testimonialIsPending={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_is_available}
                                    testimonialIsCompleted={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_is_completed}
                                    testimonialCompletedAtUnix={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_completed_at_unix}
                                    testimonialText={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_text}
                                    includeClientName={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_include_client_name}
                                    onRequestTestimonial={() => onRequestTestimonial()}
                                  />
                                )}
                                {selectedClientSubview === "settings" && (
                                  <RemoveClientPanel
                                    testimonialIsCompleted={selectedClientDetailsRequestResult.result.client_relationship_data.testimonial_completed}
                                    removeClientRequestResult={removeClientRequestResult}
                                    onRemoveClient={() => onRemoveClient()}
                                  />
                                )}
                              </div>
                            </div>
                          </CSSTransition>
                        </SwitchTransition>
                      </div>
                    </div>
                  )}
                  {(selectedClientDetailsRequestResult.state === REQUEST_FETCHING) && (
                  <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`LOADING CLIENT PROFILE...`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(selectedClientDetailsRequestResult.state === REQUEST_ERROR) && (
                    <div style={{minWidth: "100%"}}>
                      <div style={{minWidth: "100%", display: "flex", justifyContent: "center"}}>
                        <div style={{maxWidth: "85%"}}>
                          <div>
                            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
                              {`ERROR LOADING CLIENT PROFILE`}
                            </div>
                            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                              {`There was an error loading your client's details - please retry.`}
                            </div>
                            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                              <button
                                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 13px 15px", transition: "color 200ms", border: "none"}}
                                onClick={() => {
                                  onLoadClientDetails()
                                }}
                              >
                                <span>
                                  {"RETRY"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    )
  }
}

const ClientListTable = ({clientPreviews, isDesktop, onSelectClient}) => (
  <div>
    <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
      <div style={{backgroundColor: "#fbfbfc", color: "#999999", fontFamily: "Futura Medium", fontSize: "min(3vw, 14px)", padding: "min(3.5vw, 16px)"}}>
        <div style={{width: "40%", display: "inline-block"}}>
          {"CLIENT DETAILS"}
        </div>
        {isDesktop && (
          <div style={{width: "12%", display: "inline-block", textAlign: "end"}}>
            {"DISCOVERY"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "12%", display: "inline-block", textAlign: "end"}}>
            {"TESTIMONIAL"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "12%", display: "inline-block", textAlign: "end"}}>
            {"CHECK INS"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "12%", display: "inline-block", textAlign: "end"}}>
            {"HRV READINGS"}
          </div>
        )}
        {isDesktop && (
          <div style={{width: "12%", display: "inline-block", textAlign: "end"}}>
            {"EXERCISES"}
          </div>
        )}
      </div>
      {clientPreviews.map(preview => (
        <div key={preview.id} style={{width: "100%", borderTop: "1px #dddddd solid", fontSize: "min(4vw, 18px)", fontFamily: "Futura"}}>
          <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
            <div style={{width: isDesktop ? "40%" : "100%", display: "inline-block"}}>
              <span onClick={() => onSelectClient(preview.id)} style={{textDecoration: "underline", fontSize: "min(4.5vw, 20px)", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.first_name}
              </span>
              <div style={{color: "#777777", fontSize:" min(3vw, 15px)", overflow: "hidden", margin: "min(1.75vw, 8px) 0px", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {preview.email_address}
              </div>
              <div style={{color: "#000000", fontSize:" min(2.5vw, 12px)", fontFamily: "Futura Medium", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center"}}>
                <ActivityArrowSvg size={"min(2.5vw, 8px)"} color={"#000000"} rotation={((preview.energy_type === "medium") ? 90 : (preview.energy_type === "low" ? 180 : 0))} />
                <span>{preview.energy_type === "low" ? "GROUNDED ENERGY" : (preview.energy_type === "high" ? "HIGH ENERGY" : "BALANCED ENERGY")}</span>
                <span style={{fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Medium", margin: "0px 3px"}}>{"•"}</span>
                <span>{`CONNECTED ${getAbbreviatedDurationSinceTimestamp(preview.linked_account_at_unix)}`}</span>
              </div>
            </div>
            {isDesktop && (
              <div style={{width: "12%", display: "inline-block", height: 22, textAlign: "end"}}>
                <CompletionCheckIcon
                  isComplete={preview.completed_discovery_quiz}
                  marginTop={"0px"}
                  checkboxBaseClassName={"checkbox-plan-button"}
                />
              </div>
            )}
            {isDesktop && (
              <div style={{width: "12%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.testimonial_is_available ? (
                  <TimerSvg
                    size={22}
                    color={"#CFD2DD"}
                  />
                ) : (
                  <CompletionCheckIcon
                    isComplete={preview.testimonial_is_completed}
                    marginTop={"0px"}
                    checkboxBaseClassName={"checkbox-plan-button"}
                  />
                )}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "12%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.check_in_completion_count}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "12%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.hrv_completion_count}
              </div>
            )}
            {isDesktop && (
              <div style={{width: "12%", display: "inline-block", height: 22, textAlign: "end"}}>
                {preview.training_completion_count}
              </div>
            )}
          </div>
        </div>
      ))}
      <div style={{width: "100%", backgroundColor: "#fbfbfc", color: (clientPreviews.length > 0) ? "#999999" : "#777777", borderTop: "1px #dddddd solid", fontSize: "min(3.75vw, 16px)", fontFamily: "Futura Book"}}>
        <div style={{display: "flex", alignItems: "center", padding: "min(3.5vw, 16px)"}}>
          {(clientPreviews.length > 0) ? "Click on a client's name to view their insights." : "Add your first client to view their insights here."}
        </div>
      </div>
    </div>
  </div>
)

class RemoveClientPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      removeConfirmationValue: ""
    }
  }

  render() {

    const {
      testimonialIsCompleted,
      removeClientRequestResult,
      onRemoveClient,
    } = this.props

    const {removeConfirmationValue} = this.state

    const removeClientMemberButtonDisabled = (removeConfirmationValue.toLowerCase() !== "remove") || (removeClientRequestResult.state === REQUEST_FETCHING) || (removeClientRequestResult.state === REQUEST_SUCCESS)

    return (
      <div style={{backgroundColor: "#FFFFFF", marginTop: "6vh", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
        <div style={{padding: "min(5vw, 22px)"}}>
          <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
            {"REMOVE FROM DASHBOARD"}
          </div>
          <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura", marginTop: "2vh"}}>
            {`Note: Removing a client from your dashboard can't be undone${testimonialIsCompleted ? ", and will delete their testimonial" : ""}. To confirm, please enter the word "remove" in the input below:`}
          </div>
          <div style={{marginTop: "2vh"}}>
            <input
              type="text"
              value={removeConfirmationValue}
              autoCapitalize="none"
              autoCorrect="false"
              spellCheck={false}
              onChange={(e) => {
                this.setState({...this.state, removeConfirmationValue: e.target.value})
              }}
              onPaste={(e) => {
                this.setState({...this.state, removeConfirmationValue: e.target.value})
              }}
              style={{backgroundColor: "#fbfbfc"}}
              placeholder={`Type "remove" to confirm...`}
              className={"application-input"}
            />
          </div>
          <div style={{display: "flex", justifyContent: "start", marginTop: "2vh", width: "100%"}}>
            <button
              style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: removeClientMemberButtonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
              onClick={() => {
                onRemoveClient()
              }}
              disabled={removeClientMemberButtonDisabled}
            >
              <span>
                {(removeClientRequestResult.state === REQUEST_SUCCESS) ? "REMOVED CLIENT." : ((removeClientRequestResult.state === REQUEST_FETCHING) ? "REMOVING CLIENT..." : "REMOVE CLIENT")}
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}


class RequestClientTestimonialPanel extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      testimonialTextIsCopied: false,
      testimonialThemeLightMode: false,
      showTestimonialFullText: false,
      downloadingImageRequestState: REQUEST_UNSTARTED
    }
  }

  render() {

    const {
      clientFirstName,
      clientCountryCode,
      connectedAtUnix,
      existingTestimonialText,
      testimonialIsPending,
      testimonialIsCompleted,
      testimonialCompletedAtUnix,
      testimonialText,
      includeClientName,
      requestTestimonialRequestResult,
      onRequestTestimonial,
      isDesktop
    } = this.props

    const {
      testimonialTextIsCopied,
      testimonialThemeLightMode,
      showTestimonialFullText,
    } = this.state

    const buttonDisabled = (requestTestimonialRequestResult.state === REQUEST_FETCHING) || (requestTestimonialRequestResult.state === REQUEST_SUCCESS)

    const testimonialDisplayString = `"${testimonialText}"`

    return (
      testimonialIsCompleted ? (
        <div style={{marginTop: "6vh", maxWidth: 500}}>
          <InlineClientTestimonial
            domNodeId={ClientTestimonialDomId}
            isLightTheme={testimonialThemeLightMode}
            showFullText={showTestimonialFullText}
            name={includeClientName ? clientFirstName : "Anonymous Client"}
            countryCode={clientCountryCode}
            testimonialText={testimonialDisplayString}
            receivedAtUnix={testimonialCompletedAtUnix}
          />
          <div style={{display: "flex", alignItems: "center", justifyContent: "left", width: "100%"}}>
            <ConfirmSection
              label={"Light Theme"}
              isConfirmed={testimonialThemeLightMode}
              onToggleConfirm={(val) => {
                this.setState({testimonialThemeLightMode: val})
              }}
              fontFamily={"Futura"}
              marginTop={"2vh"}
              color={"#000000"}
            />
            <div style={{width: "1em"}} />
            <ConfirmSection
              label={"Show Full Text"}
              isConfirmed={showTestimonialFullText}
              onToggleConfirm={(val) => {
                this.setState({showTestimonialFullText: val})
              }}
              fontFamily={"Futura"}
              marginTop={"2vh"}
              color={"#000000"}
            />
          </div>
          <div style={{display: "flex", width: "100%", justifyContent: "center", color: "#CFD2DD", marginTop: "2vh"}}>
            <button
              style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
              onClick={() => {
                toBlob(document.getElementById(ClientTestimonialDomId))
                  .then(function (blob) {
                    if (window.saveAs) {
                      window.saveAs(blob, 'testimonial.png');
                    } else {
                      saveAs(blob, 'testimonial.png');
                    }
                  });
              }}
            >
              <span>
                {"DOWNLOAD IMAGE"}
              </span>
              <div style={{height: "min(5.5vw, 22px)", width: "min(5.5vw, 22px)", marginInlineStart: "0.3em", borderRadius: "50%", overflow: "hidden"}}>
                <SendArrowSvg
                  size={"min(5.5vw, 22px)"}
                  color={"#000000"}
                  rotation={180}
                  circleColor={"#FFFFFF"}
                />
              </div>
            </button>
          </div>
          <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
            <button
              style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: buttonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
              onClick={() => {
                onRequestTestimonial()
              }}
              disabled={buttonDisabled}
            >
              <span>
                {(requestTestimonialRequestResult.state === REQUEST_SUCCESS) ? "TESTIMONIAL REQUESTED." : ((requestTestimonialRequestResult.state === REQUEST_FETCHING) ? "REQUESTING..." : "REQUEST UPDATE")}
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div style={{backgroundColor: "#FFFFFF", marginTop: "6vh", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
          <div style={{padding: "min(5vw, 22px)"}}>
            <div>
              <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura", marginBottom: "2vh"}}>
                {testimonialIsPending ? "TESTIMONIAL REQUESTED" : "REQUEST A TESTIMONIAL"}
              </div>
              <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                {`Testimonials help you build authority + trust with your audience, and book new clients.`}
              </div>
              {testimonialIsPending ? (
                <div>
                  <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                    {`You've requested a testimonial from ${clientFirstName} via email -- when they respond, it will show up here.`}
                  </div>
                  <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: buttonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                      onClick={() => {
                        onRequestTestimonial()
                      }}
                      disabled={buttonDisabled}
                    >
                      <span>
                        {(requestTestimonialRequestResult.state === REQUEST_SUCCESS) ? "REQUEST RESENT." : ((requestTestimonialRequestResult.state === REQUEST_FETCHING) ? "REQUESTING..." : "RESEND REQUEST")}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", marginTop: "2vh"}}>
                    {`You started working with ${clientFirstName} ${getReadableDurationSinceTimestamp(connectedAtUnix)} -- click below to request a testimonial via email:`}
                  </div>
                  <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: buttonDisabled ? "rgba(255, 255, 255, 0.4)" : "#FFFFFF", backgroundColor: "#000000", padding: "17px 15px 17px 15px", transition: "color 200ms", border: "none"}}
                      onClick={() => {
                        onRequestTestimonial()
                      }}
                      disabled={buttonDisabled}
                    >
                      <span>
                        {(requestTestimonialRequestResult.state === REQUEST_SUCCESS) ? "TESTIMONIAL REQUESTED." : ((requestTestimonialRequestResult.state === REQUEST_FETCHING) ? "REQUESTING..." : "REQUEST TESTIMONIAL")}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    )
  }
}

class ClientInsightResults extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedDropDownIndex: 0
    }
  }

  render() {

    const {
      loggedInUserData,
      timeSeriesUserInsightsData,
      todayUserInsightsData,
      memberHabitStatistics,
      timeOfDay,
      topHabit,
      topHabitText,
      topLifeAspect,
      topLifeAspectText,
      isDesktopWidth,
    } = this.props

    const {
      selectedDropDownIndex
    } = this.state

    const hrvBaselineDays = Math.max(getDaysSinceTimestamp(loggedInUserData.hrv_baseline_start_unix), (!!(timeSeriesUserInsightsData.hrv) ? timeSeriesUserInsightsData.hrv.length : 0))
    const hrvBaselineAvailable = ((hrvBaselineDays >= 3) && (loggedInUserData.hrv_baseline_ms > 0))

    const defaultTopHabitText = `This habit is important to ${loggedInUserData.first_name}'s nervous system's balance - ensure they prioritize it to optimize their NEUROFIT results.`
    const defaultTopLifeAspectText = `Addressing this life aspect will best support ${loggedInUserData.first_name}'s nervous system balance. Have them focus on this life aspect during BALANCE training and CLEAR sessions.`

    return (
      <div>
        <div style={{fontSize: "min(6vw, 26px)", marginTop: "min(16vh, 80px)", fontFamily: "Futura", fontWeight: "normal"}}>
          {`KEY TAKEAWAYS`}
        </div>
        {(timeSeriesUserInsightsData.check_in.length > 0) && (
          <div style={{fontFamily: "Futura", fontWeight: "normal", fontSize: "min(4vw, 16px)", textAlign: "start", color: "#000000"}}>
            {`${timeSeriesUserInsightsData.check_in.length}-DAY AVERAGE`}
          </div>
        )}
        <div style={isDesktopWidth ? {display: "flex", alignItems: "top", justifyContent: "space-between"} : {}}>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 2) ? (
              <PieChartInsightSection
                title={`I. ${loggedInUserData.check_in_energy_level === "low" ? "GROUNDED" : (loggedInUserData.check_in_energy_level === "high" ? "HIGH ENERGY" : "BALANCED")} CHECK-INS`}
                marginTop={"max(2vh, 8px)"}
                disableDropDown
                dataIsAvailable={(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 2)}
                data={timeSeriesUserInsightsData.check_in_average_state[0].states}
                insightText={getInsightForCheckInInsight(loggedInUserData.first_name, loggedInUserData.check_in_energy_level)}
              />
            ) : (
              <PendingResultsPanel
                title={"I. CHECK-IN ENERGY TYPE"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for 2 days of check-in data..."}
                color={"#90939a"}
              />
            )}
          </div>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 5) ? (
              (!!(topHabit)) ? (
                <HabitBarChartInsightSection
                  title={`II. TOP HABIT`}
                  marginTop={"max(2vh, 8px)"}
                  dataIsAvailable={(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 5)}
                  data={[topHabit]}
                  insightText={topHabitText || defaultTopHabitText}
                  firstName={loggedInUserData.first_name}
                />
              ) : (
                <PendingResultsPanel
                  title={"II. TOP HABIT"}
                  marginTop={"max(2vh, 8px)"}
                  pendingText={"Loading Top Habit Insight..."}
                  color={"#90939a"}
                />
              )
            ) : (
              <PendingResultsPanel
                title={"II. HABIT INSIGHT"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for 5 days of check-in data..."}
                color={"#90939a"}
              />
            )}
          </div>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 5) ? (
              (!!(topLifeAspect)) ? (
                <PieChartInsightSection
                  title={`III. ${topLifeAspect.name.toUpperCase()}`}
                  marginTop={"max(2vh, 8px)"}
                  disableDropDown
                  dataIsAvailable={(Object.keys(timeSeriesUserInsightsData.check_in_average_state).length >= 5)}
                  data={topLifeAspect.states}
                  insightText={topLifeAspectText || defaultTopLifeAspectText}
                />
              ) : (
                <PendingResultsPanel
                  title={"III. TOP LIFE ASPECT"}
                  marginTop={"max(2vh, 8px)"}
                  pendingText={"Loading Life Aspect Insight..."}
                  color={"#90939a"}
                />
              )
            ) : (
              <PendingResultsPanel
                title={"III. LIFE ASPECT INSIGHT"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for 5 days of check-in data..."}
                color={"#90939a"}
              />
            )}
          </div>
        </div>

        <div style={isDesktopWidth ? {display: "flex", alignItems: "top", justifyContent: "space-between", marginTop: "min(8vh, 40px)"} : {marginTop: "min(8vh, 40px)"}}>
          <div style={isDesktopWidth ? {width: "45%"} : {}}>
            <div style={{fontSize: "min(6vw, 26px)", marginTop: "min(8vh, 40px)", fontFamily: "Futura", fontWeight: "normal"}}>
              {`HEART RATE VARIABILITY`}
            </div>
            {(hrvBaselineDays > 3) && (
              <div style={{fontFamily: "Futura", fontWeight: "normal", fontSize: "min(4vw, 16px)", textAlign: "start", color: "#000000"}}>
                {`COMPARED TO ${hrvBaselineDays}-DAY BASELINE`}
              </div>
            )}
            {!!(todayUserInsightsData.hrv.is_available) ? (
              <HrvResultsPanel
                title={"TODAY"}
                marginTop={"max(2vh, 8px)"}
                value={todayUserInsightsData.hrv.data}
                dataIsAvailable={!!(todayUserInsightsData.hrv.is_available)}
                valueUnit={"millisecond"}
                valueDecimalPoints={0}
                valueMetaFunction={getMetaForHeartRateVariability}
                isIncreasingStatistic
                baselineAvailable={hrvBaselineAvailable}
                valueBaseline={loggedInUserData.hrv_baseline_ms}
                valueBaselineStdDev={loggedInUserData.hrv_baseline_ms_stddev}
                hideInfoButton
              />
            ) : (
              <PendingResultsPanel
                title={"TODAY"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for today's HRV reading..."}
              />
            )}
            <LineGraph
              dataIsAvailable={hrvBaselineDays > 1}
              title={`${hrvBaselineDays}-DAY PROGRESS`}
              data={{
                labels: timeSeriesUserInsightsData.hrv.map(d => readableDate(d.local_date)),
                series: [timeSeriesUserInsightsData.hrv.map(d => d.avg_hrv_rmssd_ms)],
              }}
              labelInterpolationFnc={(val) => `${val}ms`}
              highYValue={Math.ceil((Math.max(...(timeSeriesUserInsightsData.hrv.map(d => d.avg_hrv_rmssd_ms)))) / 20.0) * 20}
              pendingTitle={"HRV OVER TIME"}
              pendingText={"Waiting for more HRV data..."}
            />
          </div>

          <div style={isDesktopWidth ? {width: "45%"} : {}}>
            <div style={{fontSize: "min(6vw, 26px)", marginTop: "min(8vh, 40px)", fontFamily: "Futura", fontWeight: "normal"}}>
              {`NEUROFIT BALANCE™`}
            </div>
            {(hrvBaselineDays > 3) && (
              <div style={{fontFamily: "Futura", fontWeight: "normal", fontSize: "min(4vw, 16px)", textAlign: "start", color: "#000000"}}>
                {`COMPARED TO ${hrvBaselineDays}-DAY BASELINE`}
              </div>
            )}
            {!!(todayUserInsightsData.balance.is_available) ? (
              <HrvResultsPanel
                title={"TODAY"}
                marginTop={"max(2vh, 8px)"}
                value={todayUserInsightsData.balance.data * 100.0}
                dataIsAvailable={!!(todayUserInsightsData.balance.is_available)}
                isPercentage
                valueDecimalPoints={0}
                isIncreasingStatistic
                valueMetaObject={getMetaForRecoveryScoreHomePage(todayUserInsightsData.balance.data * 100.0, getTimeOfDay(todayUserInsightsData.balance.created_at_unix), timeOfDay, loggedInUserData.first_name)}
                baselineAvailable={false}
                backgroundColor={getMetaForRecoveryScoreHomePage(todayUserInsightsData.balance.data * 100.0, getTimeOfDay(todayUserInsightsData.balance.created_at_unix), timeOfDay, loggedInUserData.first_name).color}
                valueBaseline={undefined}
                valueBaselineStdDev={undefined}
                valueDescriptorNameColor={"#000000"}
                hideInfoButton
              />
            ) : (
              <PendingResultsPanel
                title={"DAILY SCORE"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for today's Balance Score..."}
              />
            )}
            <LineGraph
              dataIsAvailable={((hrvBaselineDays >= 3) && (timeSeriesUserInsightsData.hrv.filter(v => v.avg_balance_score > 0).length >= 2))}
              title={`${hrvBaselineDays}-DAY RESULTS`}
              data={{
                labels: timeSeriesUserInsightsData.hrv.filter(v => v.avg_balance_score > 0).map(d => readableDate(d.local_date)),
                series: [timeSeriesUserInsightsData.hrv.filter(v => v.avg_balance_score > 0).map(d => d.avg_balance_score * 100.0)],
              }}
              labelInterpolationFnc={(val) => `${val}%`}
              highYValue={100}
              pendingTitle={"BALANCE SCORE OVER TIME"}
              pendingText={"Waiting for more BALANCE data..."}
            />
          </div>
        </div>


        <div style={{fontSize: "min(6vw, 26px)", marginTop: "min(16vh, 80px)", fontFamily: "Futura", fontWeight: "normal"}}>
          {`HABITS`}
        </div>
        {(timeSeriesUserInsightsData.check_in.length > 0) && (
          <div style={{fontFamily: "Futura", fontWeight: "normal", fontSize: "min(4vw, 16px)", textAlign: "start", color: "#000000"}}>
            {`${timeSeriesUserInsightsData.check_in.length}-DAY AVERAGE`}
          </div>
        )}
        <div style={isDesktopWidth ? {display: "flex", alignItems: "top", justifyContent: "space-between"} : {}}>
          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0) ? (
              <HabitBarChartInsightSection
                title={"EMBODY"}
                marginTop={"max(2vh, 8px)"}
                dataIsAvailable={Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0}
                data={timeSeriesUserInsightsData.check_in_average_habits.filter(habit => habit.is_embodiment)}
                insightTextFunction={getInsightForEmbodiment}
                memberHabitStatistics={memberHabitStatistics}
                firstName={loggedInUserData.first_name}
              />
            ) : (
              <PendingResultsPanel
                title={"EMBODY"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for check-in results..."}
                color={"#90939a"}
              />
            )}
          </div>

          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0) ? (
              <HabitBarChartInsightSection
                title={"PREVENT"}
                marginTop={"max(2vh, 8px)"}
                dataIsAvailable={Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0}
                data={timeSeriesUserInsightsData.check_in_average_habits.filter(habit => habit.is_avoidance)}
                insightTextFunction={getInsightForAvoidance}
                memberHabitStatistics={memberHabitStatistics}
                firstName={loggedInUserData.first_name}
              />
            ) : (
              <PendingResultsPanel
                title={"PREVENT"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for check-in results..."}
                color={"#90939a"}
              />
            )}
          </div>

          <div style={isDesktopWidth ? {width: "30%"} : {}}>
            {(Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0) ? (
              <HabitBarChartInsightSection
                title={"NOURISH"}
                marginTop={"max(2vh, 8px)"}
                dataIsAvailable={Object.keys(timeSeriesUserInsightsData.check_in_average_habits).length > 0}
                data={timeSeriesUserInsightsData.check_in_average_habits.filter(habit => habit.is_self_care)}
                insightTextFunction={getInsightForSelfCare}
                memberHabitStatistics={memberHabitStatistics}
                firstName={loggedInUserData.first_name}
              />
            ) : (
              <PendingResultsPanel
                title={"NOURISH"}
                marginTop={"max(2vh, 8px)"}
                pendingText={"Waiting for check-in results..."}
                color={"#90939a"}
              />
            )}
          </div>
        </div>

        <div style={{fontSize: "min(6vw, 26px)", marginTop: "min(16vh, 80px)", fontFamily: "Futura", fontWeight: "normal"}}>
          {`CHECK-INS`}
        </div>
        <div style={{maxWidth: 400}}>
          {(timeSeriesUserInsightsData.check_in_average_state.length > 0) ? (
            <PieChartInsightSection
              title={(timeSeriesUserInsightsData.check_in_average_state.length > 0) ? timeSeriesUserInsightsData.check_in_average_state[selectedDropDownIndex].name.toUpperCase() : "CHECK-IN AVERAGES"}
              timeIntervalTitle={timeSeriesUserInsightsData.check_in.length > 0 ? `LAST ${timeSeriesUserInsightsData.check_in.length}D` : "RECENT"}
              dataIsAvailable={timeSeriesUserInsightsData.check_in_average_state.length > 0}
              data={(timeSeriesUserInsightsData.check_in_average_state.length > 0) ? timeSeriesUserInsightsData.check_in_average_state[selectedDropDownIndex].states : []}
              insightTextFunction={selectedDropDownIndex === 0 ? getInsightForOverallAverageStatePercentage : getInsightForLifeAspectAverageStatePercentage}
              firstName={loggedInUserData.first_name}
              dropDownOptions={(timeSeriesUserInsightsData.check_in_average_state.length > 0) ? timeSeriesUserInsightsData.check_in_average_state.map((v, idx) => ({label: v.name.toUpperCase(), value: idx})) : []}
              selectedIndex={selectedDropDownIndex}
              onDropDownSelect={(val) => {
                this.setState({...this.state, selectedDropDownIndex: val})
              }}
            />
          ) : (
            <PendingResultsPanel
              title={"AVERAGES OVER TIME"}
              pendingText={"Waiting for check-in results..."}
              color={"#90939a"}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ClientsTab